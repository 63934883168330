export const PAYMENT_METHOD = {
  method: 'GET',
  reducerKey: 'paymentMethodAnalytics',
  url: 'v2/analyseit/order/payment/summary',
};

export const EXPORT_ITEM_LEVEL_BREAKDOWN = {
  method: 'GET',
  url: 'v2/analyseit/order/%s/analytics/export',
};

export const EXPORT_ORDER_BREAKDOWN_ORDER = {
  method: 'GET',
  url: 'v2/analyseit/order/export',
};

export const EXPORT_ORDER_BREAKDOWN_ITEM = {
  method: 'GET',
  url: 'v2/analyseit/order/menus/export',
};

export const PROMO_SUMMARY = {
  method: 'GET',
  url: 'v2/analyseit/order/promo/summary',
};

export const PROMO_LIST = {
  method: 'GET',
  url: 'v2/analyseit/order/promo/analytics',
  isPaginated: false,
};

export default {
  PAYMENT_METHOD,
};
