import { SUPER_ADMIN_USERS } from '../config/env';
import { isEmpty } from './index';

export const hasPermission = (currentUser, permission) => {
  const permissions =
    (currentUser &&
      currentUser.permissions &&
      currentUser.permissions.length &&
      currentUser.permissions.map(p => p.toLowerCase())) ||
    [];

  return permissions.includes(permission.toLowerCase());
};

export const isAllowedUser = currentUser => {
  if (isEmpty(SUPER_ADMIN_USERS)) return true;
  const allowedUsers = SUPER_ADMIN_USERS.split(',');
  return allowedUsers.includes(currentUser.email);
};

export const PERMISSIONS = {
  FETCH_LIVE_MONITOR: 'fetch.live.monitor',
  FETCH_CAMPAIGN: 'fetch.campaign',
  CREATE_CAMPAIGN: 'create.campaign',
  FETCH_FEATURE_ALL: 'fetch.feature.all',
  FETCH_MENU_PULLING: 'fetch.menu.pulling',
  FETCH_CRM_ALL: 'fetch.crm.all',
  FETCH_INVOICE: 'fetch.invoice',
};
