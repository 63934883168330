export const MULTI_LANG_TEXTS = {
  PROMO: {
    PROMO_TITLE: 'Promo Revenue',
    PROMO_SUMMARY_TOTAL_PROMOS: 'Total Promos',
    PROMO_SUMMARY_ACTIVE_PROMOS: 'Active Promos',
    PROMO_TABLE_HEADER_NAME: 'Promo Name',
    PROMO_TABLE_HEADER_NET_REVENUE: 'Gross Order Value / Net Sales',
    PROMO_TABLE_HEADER_DISCOUNT_APPLIED: 'Discount Applied',
    PROMO_TABLE_HEADER_TRANSATION_COUNT: 'No. of Transactions',
    PROMO_TABLE_HEADER_USERS_CLAIMED: 'Users Claimed',
  },
};
