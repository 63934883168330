import { subDays } from 'date-fns/esm';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  SCDatepicker,
  SCInput,
  SCSelect,
  SCSelectV3,
  SCTimepicker,
} from 'rollup-allspark';
import { ORDER_STATUS } from '../../config';
import {
  ORDER_STATUS_LIST,
  ORDER_TYPE_LIST,
} from '../../config/constants/orders';
import { isEmpty } from '../../utils';
import { makeSelectGlobalProperty } from '../App/selectors';
import { getList, updateEntity } from './actions';
import { makeSelectProperty } from './selector';

const OrderInfo = ({
  externalId,
  paymentChannels,
  paymentStatus,
  paymentStatusList,
  selectedDate,
  selectedPaymentChannel,
  selectedTime,
  shortId,
  status,
  updateField,
  type,
}) => {
  const ORDER_STATUSES = ORDER_STATUS_LIST.filter(
    os => os.id === ORDER_STATUS.CANCELLED || os.id === ORDER_STATUS.DELIVERED,
  );

  const onDateChange = sd => {
    const sDate = isEmpty(sd.error) ? sd.data[0] || sd.data : null;

    updateField({
      selectedDate: sDate,
    });
  };

  return (
    <div className="bg-white px-3 py-4 rounded flex flex-col gap-y-4 border border-grey">
      <div className="flex flex-col gap-y-2">
        <div className="flex justify-between">
          <div className="flex items-center text-base">Order Type</div>
          <div className="flex items-center bg-red-lighter rounded-full px-2 py-1 text-mini">
            Required
          </div>
        </div>
        <SCSelectV3
          title=""
          options={ORDER_TYPE_LIST}
          track-by="id"
          label="label"
          value={type}
          onSelect={v => updateField({ type: v })}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex items-center text-base">External ID</div>
          <div className="flex items-center bg-red-lighter rounded-full px-2 py-1 text-mini">
            Required
          </div>
        </div>
        <SCInput
          type="text"
          inputClass="w-full border border-grey rounded-lg"
          value={externalId}
          handleChange={e => updateField({ externalId: e.target.value })}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex items-center text-base">Short ID</div>
          <div className="flex items-center bg-red-lighter rounded-full px-2 py-1 text-mini">
            Required
          </div>
        </div>
        <SCInput
          type="text"
          inputClass="w-full border border-grey rounded-lg"
          value={shortId}
          handleChange={e => updateField({ shortId: e.target.value })}
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex justify-between">
          <div className="flex items-center text-base">Order Date</div>
          <div className="flex items-center bg-red-lighter rounded-full px-2 py-1 text-mini">
            Required
          </div>
        </div>
        <SCDatepicker
          helpText="Select Order Date"
          elementType="input"
          placeholder="Select order date"
          isClearable
          startDate={selectedDate}
          dateFormat="yyyy-MM-dd"
          displayFormat="MMM dd, yyyy"
          minDate={subDays(new Date(), 90)}
          maxDate={new Date()}
          disableLabel
          onSelect={onDateChange}
          containerClass="!m-0"
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex justify-between">
          <div className="flex items-center text-base">Order Time</div>
          <div className="flex items-center bg-red-lighter rounded-full px-2 py-1 text-mini">
            Required
          </div>
        </div>
        <SCTimepicker
          value={selectedTime}
          minuteStep={1}
          minuteStart={0}
          handleChange={ot => updateField({ selectedTime: ot })}
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex justify-between">
          <div className="flex items-center text-base">Order Status</div>
          <div className="flex items-center bg-red-lighter rounded-full px-2 py-1 text-mini">
            Required
          </div>
        </div>
        <SCSelectV3
          title=""
          options={ORDER_STATUSES}
          track-by="id"
          label="status"
          value={status}
          onSelect={s => updateField({ status: s })}
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex justify-between">
          <div className="flex items-center text-base">Payment</div>
          <div className="flex items-center bg-red-lighter rounded-full px-2 py-1 text-mini">
            Required
          </div>
        </div>
        <div className="flex gap-x-4">
          <div className="w-1/2">
            <SCSelect
              title="Method"
              options={paymentChannels}
              track-by="id"
              label="title"
              value={selectedPaymentChannel}
              onSelect={s => updateField({ selectedPaymentChannel: s })}
            />
          </div>
          <div className="w-1/2">
            <SCSelect
              title="Status"
              options={paymentStatusList}
              track-by="id"
              label="title"
              value={paymentStatus}
              onSelect={s => updateField({ paymentStatus: s })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

OrderInfo.propTypes = {
  externalId: PropTypes.string,
  paymentChannels: PropTypes.array,
  paymentStatus: PropTypes.object,
  paymentStatusList: PropTypes.array,
  selectedDate: PropTypes.object,
  selectedPaymentChannel: PropTypes.object,
  selectedTime: PropTypes.number,
  shortId: PropTypes.string,
  status: PropTypes.object,
  updateField: PropTypes.func,
  type: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  branches: makeSelectProperty('branches'),
  brands: makeSelectProperty('brands'),
  businesses: makeSelectGlobalProperty('businessList'),
  externalId: makeSelectProperty('externalId'),
  paymentChannels: makeSelectProperty('paymentChannels'),
  paymentStatus: makeSelectProperty('paymentStatus'),
  paymentStatusList: makeSelectProperty('paymentStatusList'),
  selectedBranch: makeSelectProperty('selectedBranch'),
  selectedBrand: makeSelectProperty('selectedBrand'),
  selectedBusiness: makeSelectProperty('selectedBusiness'),
  selectedDate: makeSelectProperty('selectedDate'),
  selectedPaymentChannel: makeSelectProperty('selectedPaymentChannel'),
  selectedTime: makeSelectProperty('selectedTime'),
  shortId: makeSelectProperty('shortId'),
  status: makeSelectProperty('status'),
  type: makeSelectProperty('type'),
});

const mapDispatchToProps = dispatch => ({
  getBranches: meta => dispatch(getList(meta)),
  getBrands: meta => dispatch(getList(meta)),
  updateField: meta => dispatch(updateEntity(meta)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrderInfo);
