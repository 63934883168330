import React from 'react';
import { clsx } from 'clsx';
import JsxParser from 'react-jsx-parser';
import { twMerge } from 'tailwind-merge';
import { isArray } from './isArray';

const cloneDeep = obj => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  const clone = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach(key => {
    clone[key] = cloneDeep(obj[key]);
  });

  return clone;
};

const conformsTo = (object, schema) =>
  Object.keys(schema).every(key => {
    const validator = schema[key];
    const value = object[key];
    return validator(value);
  });

const isEmpty = value => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  return false;
};

const isEqual = (value1, value2) => {
  // Check if both values are null or undefined
  if (value1 === value2) {
    return true;
  }

  // Check if both values are arrays and their lengths are equal
  if (
    Array.isArray(value1) &&
    Array.isArray(value2) &&
    value1.length === value2.length
  ) {
    return value1.every((element, index) => isEqual(element, value2[index]));
  }

  // Check if both values are objects and have the same keys and values
  if (
    typeof value1 === 'object' &&
    typeof value2 === 'object' &&
    value1 !== null &&
    value2 !== null
  ) {
    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    return keys1.every(key => isEqual(value1[key], value2[key]));
  }

  // Check if both values are strings, numbers, or booleans
  return value1 === value2;
};

const isFunction = value => typeof value === 'function';

const isObject = value =>
  value !== null && typeof value === 'object' && !Array.isArray(value);

const isString = value => typeof value === 'string' || value instanceof String;

const isNumber = value =>
  typeof value === 'number' && Number.isNaN(value) === false;

const printf = (string, ...values) =>
  values.reduce(
    (formattedString, value) => formattedString.replace('%s', value),
    string,
  );

/*
 * Uses React-jsx-parser package to convert jsx string to React jsx elements
 * See more: https://www.npmjs.com/package/react-jsx-parser
 * @param {string} string - jsx string to convert
 * @param {Object} props - object to pass in react-jsx-parser & printf
 * @param {Array} props.values - array to pass in printf before parsing the jsx string
 * @param {Object} props.components - object map of React component definitions
 * @param {Object} props.bindings - object map of additional variable and function definitions
 *
 * @returns {React.ReactElement}
 */
const printfEl = (
  string,
  { values = [], components = {}, bindings = {}, ...otherProps },
) => (
  <JsxParser
    components={components}
    bindings={bindings}
    jsx={printf(string, ...values)
      .replaceAll(/\s+/g, ' ')
      .trim()}
    renderInWrapper={false}
    disableKeyGeneration
    disableFragment
    blacklistedAttrs={[]}
    {...otherProps}
  />
);

const isValidHttpUrl = string => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  const matcher = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;

  return Boolean(url.href) && matcher.test(url.href);
};

const filterEmptyValues = obj =>
  Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        let val = value;
        if (isObject(value) && !isArray(value)) {
          val = filterEmptyValues(value);
        } else if (isArray(value)) {
          val = value
            .map(item => (isObject(item) ? filterEmptyValues(item) : item))
            .filter(item => !isEmpty(item));
        }

        return [key, isEmpty(val) ? undefined : val];
      })
      .filter(([, value]) => value !== undefined), // Filter out undefined values
  );

/**
 * Takes multiple comma separated classes (strings & objects) and returns the merged class names using the clsx and twMerge functions.
 *
 * @param {...*} classes - The classes (strings & objects) to merge into class names.
 * @return {string} - The merged class names.
 */
// clsx: For object based classes & twMerge: For merging tailwind classes
const cn = (...classes) => twMerge(clsx(...classes));

const generateRandomId = (length = 8) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';

  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }

  return randomId;
};

const concat = (arrStr = [], separator = '-') =>
  arrStr.filter(s => !isEmpty(s)).join(separator);

export {
  cloneDeep,
  cn,
  concat,
  conformsTo,
  filterEmptyValues,
  isEmpty,
  isEqual,
  isFunction,
  isNumber,
  isObject,
  isString,
  isValidHttpUrl,
  printf,
  printfEl,
  generateRandomId,
};
