import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCInput } from 'rollup-allspark';
import SCInputWithPrefixSuffix from '../../../../components/SCInputWithPrefixSuffix';
import { cn, isEmpty } from '../../../../utils';
import { readablePrice } from '../../../../utils/getCurrency';
import { updateEntity } from '../../actions';
import { makeSelectProperty } from '../../selector';
import ModifierRow from './ModifierRow';

const ItemRow = ({
  index = null,
  isDisabled = false,
  selectedBranch = {},
  item = {},
  cartItems = [],
  updateState,
}) => {
  const {
    title = '',
    unit_price: unitPrice = 0,
    total_price: totalPrice = 0,
    quantity = 0,
    modifiers = [],
  } = item;

  const {
    currency_code: currencyCode = '',
    currency_symbol: currencySymbol = '',
  } = selectedBranch;

  const isModAddDisabled =
    isDisabled ||
    modifiers.some(
      modifier =>
        isEmpty(modifier.title) ||
        modifier.unit_price < 0 ||
        modifier.quantity < 1,
    );

  const getTotalPrice = itm => {
    const {
      unit_price: itmUnitPrice,
      quantity: itmQuantity,
      modifiers: itmModifiers,
    } = itm;

    const modTotalPrice = itmModifiers.reduce((acc, mod) => {
      const modPrice = mod.unit_price * mod.quantity;
      return acc + modPrice;
    }, 0);

    return (itmUnitPrice * itmQuantity + modTotalPrice).toFixed(2);
  };

  const handleItemChange = (name, value) => {
    const newItems = [...cartItems];

    const updatedItem = {
      ...newItems[index],
      [name]: value,
    };

    newItems[index] = {
      ...updatedItem,
      total_price: getTotalPrice(updatedItem),
    };

    updateState({
      cartItems: newItems,
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;

    handleItemChange(name, value);
  };

  const handleRemoveItem = () => {
    const newItems = cartItems.filter((_, i) => i !== index);

    updateState({
      cartItems: newItems,
    });
  };

  const handleModifierAdd = () => {
    if (isModAddDisabled) return;
    const newModifiers = [
      ...modifiers,
      { id: modifiers.length + 1, title: '', unit_price: 0, quantity: 0 },
    ];

    handleItemChange('modifiers', newModifiers);
  };

  const modifiersEl = (() => {
    if (isEmpty(modifiers)) return null;

    const rowsEl = modifiers.map((modifier, idx) => (
      <ModifierRow
        key={modifier.id}
        index={idx}
        modifier={modifier}
        modifiers={modifiers}
        currencySymbol={currencySymbol}
        handleModifierChange={values => handleItemChange('modifiers', values)}
      />
    ));

    return <div className="flex flex-col gap-y-2 mt-2">{rowsEl}</div>;
  })();

  return (
    <div className="border-b border-grey pb-4">
      <div className="flex items-center justify-between gap-x-4">
        <div className="flex items-center gap-x-4">
          <SCInput
            name="title"
            type="text"
            label="Item"
            className="w-1/2"
            placeholder="Item title"
            inputClass="border border-grey rounded-lg"
            hasError={isEmpty(title)}
            value={title}
            handleChange={handleChange}
          />
          <div className="sc-input-container w-1/5">
            <label htmlFor="unit_price" className="-mt-2 mb-1">
              Unit Price
            </label>
            <SCInputWithPrefixSuffix
              prefix={`${currencySymbol} `}
              type="number"
              placeholder="Unit Price"
              value={unitPrice}
              name="unit_price"
              id="unit_price"
              hasError={unitPrice < 1}
              handleChange={handleChange}
              className={cn({
                'border-grey-dark rounded-lg mt-1': true,
                'text-red-dark border-red-dark': unitPrice < 1,
              })}
            />
          </div>
          <SCInput
            name="quantity"
            type="number"
            label="Count"
            className="w-1/5"
            placeholder="Quantity"
            inputClass="border border-grey rounded-lg"
            hasError={quantity < 1}
            value={quantity}
            handleChange={handleChange}
          />
          <SCInput
            label="Total Item Price"
            className="w-1/5"
            inputClass="border border-grey rounded-lg"
            value={readablePrice(totalPrice * 100, {
              code: currencyCode,
              symbol: currencySymbol,
            })}
            readOnly
          />
        </div>
        <button
          type="button"
          onClick={handleRemoveItem}
          className="flex items-center justify-end gap-x-1 p-1"
        >
          <i className="kt-x text-red-dark text-sm" />
          <p className="text-xs text-red-dark font-medium line-clamp-1">
            Remove Item
          </p>
        </button>
      </div>
      <div className="ml-8">
        {modifiersEl}
        <button
          type="button"
          onClick={handleModifierAdd}
          disabled={isModAddDisabled}
          className={cn({
            'flex items-center justify-center p-5 rounded-lg bg-grey gap-x-1 w-fit h-10 mt-2': true,
            'opacity-50': isModAddDisabled,
          })}
        >
          <i className="kt-plus text-lg" />
          <p className="text-xs font-medium">Add Modifier</p>
        </button>
      </div>
    </div>
  );
};

ItemRow.propTypes = {
  index: PropTypes.number,
  isDisabled: PropTypes.bool,
  selectedBranch: PropTypes.object,
  item: PropTypes.object,
  cartItems: PropTypes.array,
  updateState: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  selectedBranch: makeSelectProperty('selectedBranch'),
  cartItems: makeSelectProperty('cartItems'),
});

const mapDispatchToProps = dispatch => ({
  updateState: meta => dispatch(updateEntity(meta)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ItemRow);
