export const AMEND_ORDER = {
  method: 'POST',
  url: 'v1/oni/amended-order',
};

export const BRANCH_LIST = {
  method: 'GET',
  reducerKey: 'branches',
  url: 'v1/branch',
  isPaginated: false,
};

export const BRAND_LIST = {
  method: 'GET',
  reducerKey: 'brands',
  url: 'v1/brand',
  isPaginated: false,
};

export const STORE_ITEMS = {
  method: 'GET',
  url: 'v1/menu-v2/store/items',
  isPaginated: false,
};

export const PAYMENT_CHANNEL_LIST = {
  method: 'GET',
  reducerKey: 'paymentChannels',
  url: 'v1/oni/payment-channels/all',
  isPaginated: false,
};

export const PAYMENT_STATUS_LIST = {
  method: 'GET',
  reducerKey: 'paymentStatusList',
  url: 'v1/oni/payment-statuses',
  isPaginated: false,
};

export const PROVIDER_BRAND_LIST = {
  method: 'GET',
  reducerKey: 'providerBrands',
  url: 'v1/brand-provider/branch/%s',
};
