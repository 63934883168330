import { subDays } from 'date-fns';
import { produce } from 'immer';
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from '../../config';
import {
  ACTIVE_ORDER_MODE,
  ACTIVE_ORDER_TABS,
  CREATE_QRIS_ID,
  ORDER_SEARCH_PARAMS,
  PAST_ORDER_TABS,
  PAYMENT_STATUS_REFUNDED_ID,
  PAYMENT_STATUS_REFUND_FAILED,
  PAYMENT_STATUS_REFUND_REQUESTED_FAILED_ID,
  PAYMENT_STATUS_REFUND_REQUESTED_ID,
} from '../../config/constants/orders';
import { LOGOUT_DONE } from '../Auth/constants';
import * as constants from './constants';
import { EDIT_CANCELLATION_REASON_DETAILS } from './constants';

export const initialState = {
  businesses: [],
  branch: [],
  brand: null,
  loading: true,
  liveOrders: {},
  liveNewOrders: [],
  liveOrder: {},
  brandLoading: false,
  branchLoading: false,
  updateOrderLoading: false,
  orderSourceGroupList: [],
  scheduledOrders: [],
  selectedOrderHistoryMode: {
    title: 'Active Orders',
    id: 'active_orders',
  },
  paymentChannelsAll: [],
  selectedPaymentStatus: null,
  selectedPaymentChannel: null,
  paymentInfoUpdated: false,
  cancellationReasonsLoading: false,
  cancellationReasonDetails: null,
  paymentChannelsByCountry: [],
  selectedBrandForIntStatus: null,
  selectedBranchForIntStatus: null,
  providersForIntStatus: [],
  brandsForIntStatus: [],
  branchesForIntStatus: [],
  integrationStatuses: [],
  isIntegrationStatusLoading: false,
  riderAllocationCancelLoading: false,
  orderAttachments: [],
  orderAttachmentsLoading: false,
  preparationTimeUpdating: false,
  orderHistory: [],
  fulfillmentHistory: [],
  isSaving: false,

  bpoUserList: [],
  branches: [],
  brands: [],
  connectedBrands: [],
  cancellationReasons: [],
  detailsLoading: false,
  fulfillmentStatusList: [],
  isLoading: false,
  isLoadingProviderIntegrations: false,
  isSearchingOrder: false,
  orderCount: {},
  orders: [],
  orderSources: [],
  orderSourcesFlat: [],
  orderView: ACTIVE_ORDER_MODE,
  page: DEFAULT_PAGE_NO,
  paymentChannels: [],
  paymentStatusList: [],
  providerIntegrations: [],
  providers: [],
  searchKey: '',
  searchedOrders: [],
  selectablePaymentStatusList: [],
  selectedActiveOrderView: ACTIVE_ORDER_TABS[0],
  selectedBranches: [],
  selectedBrands: [],
  selectedBusiness: null,
  selectedKlikitSources: [],
  selectedOrder: null,
  selectedPastOrderDateRange: {
    start: subDays(new Date(), 1),
    end: new Date(),
  },
  selectedPastOrderView: PAST_ORDER_TABS[0],
  selectedProviders: [],
  selectedSearchParam: ORDER_SEARCH_PARAMS[0],
  size: DEFAULT_PAGE_SIZE,
  status: {},
  statusList: [],
  total: 0,
};

const oniReducer = (state = initialState, action) =>
  produce(state, draft => {
    // let orderIndex;
    const statusReduce = action.status?.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {},
    );
    const actionParamKeys = Object.keys(action.params || {});

    switch (action.type) {
      case constants.DESELECT_BRANDS:
        draft.liveOrders = initialState.liveOrders;
        break;
      case constants.LOAD_ONI_BRAND:
        draft.brandLoading = true;
        draft.loading = true;
        break;
      case constants.LOAD_ONI_BRANCH:
        draft.branchLoading = true;
        draft.loading = true;
        break;
      case constants.LOAD_ONI_BRANCH_FAIL:
        draft.branchLoading = false;
        draft.loading = false;
        break;
      case constants.LOAD_ONI_BRAND_FAIL:
        draft.brandLoading = false;
        draft.loading = false;
        break;
      case constants.LOAD_ONI_BRANCH_SUCCESS:
        draft.branches = [...action.branches];
        draft.branch = [...action.branches];
        draft.branchLoading = false;
        draft.loading = false;
        break;
      case constants.LOAD_BRANCHES_FOR_INTEGRATION_STATUS_SUCCESS:
        draft.branchesForIntStatus = [...action.branches];
        break;
      case constants.LOAD_ONI_BRAND_SUCCESS:
        draft.brands = action.brands;
        draft.brandLoading = false;
        draft.loading = false;
        break;
      case constants.LOAD_BRANDS_FOR_INTEGRATION_STATUS_SUCCESS:
        draft.brandsForIntStatus = action.brands;
        break;
      case constants.CHANGE_BRAND:
        draft.brand = action.brand && action.brand.id ? action.brand : null;
        break;
      case constants.CHANGE_BRANCH:
        draft.branch = [...action.branch];
        break;
      case constants.LOAD_LIVE_ORDERS_SUCCESS:
        draft.liveOrders = action.liveOrders;
        draft.loading = false;
        break;
      case constants.LOAD_LIVE_NEW_ORDERS_SUCCESS:
        draft.liveNewOrders = action.liveNewOrders;
        draft.loading = false;
        break;
      case constants.LOAD_LIVE_NEW_ORDERS_FAILED:
        draft.loading = false;
        break;
      case constants.LOAD_LIVE_ORDERS_FAILED:
      case constants.LOAD_LIVE_ORDER_FAILED:
        draft.loading = false;
        break;

      case constants.LOAD_LIVE_ORDER_SUCCESS:
        draft.liveOrder = action.liveOrder;
        draft.loading = false;
        break;

      case constants.LOAD_STATUS_SUCCESS:
        draft.status = statusReduce;
        break;
      case constants.LOAD_PROVIDERS_SUCCESS:
        // const providerReduce = action.providers.reduce(
        //   (acc, cur) => ({
        //     ...acc,
        //     [cur.id]: cur,
        //   }),
        //   {},
        // );

        draft.providers = [...action.providers];
        break;
      case constants.START_ORDER_STATUS:
        draft.updateOrderLoading = true;
        break;
      case constants.START_ORDER_STATUS_SUCCESS:
        // draft.liveOrder = {
        //   ...draft.liveOrder,
        //   status: action.order.status,
        // };
        // orderIndex =
        //   draft.liveOrders &&
        //   draft.liveOrders.orders.findIndex(l => l.id === action.order.id);
        // draft.liveOrders.orders[orderIndex] = {
        //   ...draft.liveOrders.orders[orderIndex],
        //   status: action.order.status,
        // };
        draft.updateOrderLoading = false;
        break;
      case constants.START_ORDER_STATUS_FAILED:
        draft.updateOrderLoading = false;
        break;
      case constants.UPDATE_PAYMENT_INFO_SUCCESS:
        draft.paymentInfoUpdated = !draft.paymentInfoUpdated;
        break;
      case constants.LOAD_BIZ_ONI_SUCCESS:
        draft.businesses = action.businesses;
        break;
      case constants.CHANGE_ONI_BIZ:
        draft.selectedBusiness = action.selectedBusiness;
        if (!action.selectedBusiness) {
          draft.liveOrders = initialState.liveOrders;
          draft.branches = initialState.branches;
          draft.brands = initialState.branches;
          draft.brand = initialState.brand;
          draft.branch = initialState.branch;
          draft.liveOrder = initialState.liveOrder;
          draft.branchLoading = true;
          draft.brandLoading = true;
          draft.loading = true;
        }
        break;
      case constants.CHANGE_SELECTED_ORDER_HISTORY_MODE:
        draft.selectedOrderHistoryMode = action.selectedOrderHistoryMode;
        break;
      case constants.LOAD_ORDER_SOURCE_SUCCESS:
        draft.orderSourceGroupList = action.orderSourceGroupList;
        break;
      case constants.LOAD_LIVE_SCHEDULED_ORDERS_SUCCESS:
        draft.scheduledOrders = action.scheduledOrders;
        draft.loading = false;
        break;
      case constants.CANCEL_RIDER_ALLOCATION:
        draft.riderAllocationCancelLoading = true;
        break;
      case constants.CANCEL_RIDER_ALLOCATION_SUCCESS:
        draft.riderAllocationCancelLoading = false;
        break;
      case constants.CANCEL_RIDER_ALLOCATION_FAILED:
        draft.riderAllocationCancelLoading = false;
        break;
      case constants.GET_ORDER_ATTACHMENTS:
        draft.orderAttachmentsLoading = true;
        break;
      case constants.GET_ORDER_ATTACHMENTS_SUCCESS:
        draft.orderAttachments = action.orderAttachments;
        draft.orderAttachmentsLoading = false;
        break;
      case constants.GET_ORDER_ATTACHMENTS_FAILED:
        draft.orderAttachmentsLoading = false;
        break;
      case LOGOUT_DONE:
        draft.businesses = initialState.businesses;
        draft.liveOrder = initialState.liveOrder;
        draft.liveOrders = initialState.liveOrders;
        draft.liveNewOrders = initialState.liveNewOrders;
        draft.loading = initialState.loading;
        draft.providers = initialState.providers;
        draft.selectedBusiness = initialState.selectedBusiness;
        draft.status = initialState.status;
        draft.updateOrderLoading = initialState.updateOrderLoading;
        draft.branches = initialState.branches;
        draft.brands = initialState.brands;
        draft.brand = initialState.brand;
        draft.brandLoading = initialState.brandLoading;
        draft.branchLoading = initialState.branchLoading;
        draft.orderSourceGroupList = initialState.orderSourceGroupList;
        draft.scheduledOrders = initialState.scheduledOrders;
        draft.orderAttachments = initialState.orderAttachments;
        break;
      case constants.LOAD_PAYMENT_CHANNELS_All_SUCCESS:
        draft.paymentChannelsAll = action.paymentChannelsAll;
        break;
      case constants.LOAD_PAYMENT_CHANNELS_All_FAILED:
        draft.paymentChannelsAll = initialState.paymentChannelsAll;
        break;
      case constants.SELECTED_PAYMENT_CHANNEL:
        draft.selectedPaymentChannel = action.selectedPaymentChannel;
        break;
      case constants.RESET_SELECTED_PAYMENT_CHANNEL:
        draft.paymentChannelsAll = initialState.paymentChannelsAll;
        draft.selectedPaymentChannel = initialState.selectedPaymentChannel;
        break;
      case constants.LOAD_PAYMENT_STATUS_LIST_SUCCESS:
        draft.paymentStatusList = action.paymentStatusList;
        draft.selectablePaymentStatusList = action.paymentStatusList.filter(
          ps =>
            ![
              PAYMENT_STATUS_REFUNDED_ID,
              PAYMENT_STATUS_REFUND_REQUESTED_ID,
              PAYMENT_STATUS_REFUND_REQUESTED_FAILED_ID,
              PAYMENT_STATUS_REFUND_FAILED,
            ].includes(ps.id),
        );
        break;
      case constants.LOAD_PAYMENT_STATUS_LIST_FAILED:
        draft.paymentStatusList = initialState.paymentStatusList;
        draft.selectablePaymentStatusList =
          initialState.selectablePaymentStatusList;
        break;
      case constants.SELECTED_PAYMENT_STATUS:
        draft.selectedPaymentStatus = action.selectedPaymentStatus;
        break;
      case constants.RESET_SELECTED_PAYMENT_STATUS:
        draft.paymentStatusList = initialState.paymentStatusList;
        draft.selectedPaymentStatus = initialState.selectedPaymentStatus;
        break;
      case constants.FIND_RIDER:
        draft.updateOrderLoading = true;
        break;
      case constants.FIND_RIDER_SUCCESS:
        draft.updateOrderLoading = false;
        break;
      case constants.FIND_RIDER_FAILED:
        draft.updateOrderLoading = false;
        break;
      case constants.GET_CANCELLATION_REASONS:
        draft.cancellationReasonsLoading = true;
        break;
      case constants.GET_CANCELLATION_REASONS_SUCCESS:
        draft.cancellationReasons = action.cancellationReasons;
        draft.cancellationReasonsLoading = false;
        break;
      case constants.GET_CANCELLATION_REASONS_FAILED:
        draft.cancellationReasons = initialState.cancellationReasons;
        draft.cancellationReasonsLoading = false;
        break;
      case EDIT_CANCELLATION_REASON_DETAILS:
        draft.cancellationReasonDetails = action.cancellationReasonDetails;
        break;
      case constants.UPDATE_PREP_TIME:
        draft.preparationTimeUpdating = true;
        break;
      case constants.UPDATE_PREP_TIME_SUCCESS:
        draft.preparationTimeUpdating = false;
        break;
      case constants.UPDATE_PREP_TIME_FAILED:
        draft.preparationTimeUpdating = false;
        break;
      case constants.LOAD_PAYMENT_CHANNELS_BY_COUNTRY_SUCCESS:
        draft.paymentChannelsByCountry = action.paymentChannelsByCountry
          .map(group => {
            const channels = group.channels.filter(
              channel => channel.id !== CREATE_QRIS_ID,
            );
            return {
              ...group,
              channels,
            };
          })
          .filter(Boolean);
        break;
      case constants.LOAD_PAYMENT_CHANNELS_BY_COUNTRY_FAILED:
        draft.paymentChannelsByCountry = initialState.paymentChannelsByCountry;
        break;
      case constants.LOAD_PROVIDERS_FOR_INTEGRATION_STATUS_SUCCESS:
        draft.providersForIntStatus = [...action.providers];
        break;
      case constants.CHANGE_BRAND_FOR_INTEGRATION_STATUS:
        draft.selectedBrandForIntStatus = action.selectedBrandForIntStatus;
        break;
      case constants.CHANGE_BRANCH_FOR_INTEGRATION_STATUS:
        draft.selectedBranchForIntStatus = action.selectedBranchForIntStatus;
        break;
      case constants.LOAD_INTEGRATION_STATUS:
        draft.isIntegrationStatusLoading = true;
        break;
      case constants.LOAD_INTEGRATION_STATUS_SUCCESS:
        draft.integrationStatuses = action.integrationStatuses;
        draft.isIntegrationStatusLoading = false;
        break;
      case constants.LOAD_INTEGRATION_STATUS_FAILED:
        draft.isIntegrationStatusLoading = false;
        break;
      case constants.DELETE_ORDER:
        draft.updateOrderLoading = true;
        break;
      case constants.DELETE_ORDER_SUCCESS:
        draft.updateOrderLoading = false;
        break;
      case constants.DELETE_ORDER_FAILED:
        draft.updateOrderLoading = false;
        break;
      case constants.GET_LIST:
        draft[action.meta?.loaderKey || 'isLoading'] = true;
        break;
      case constants.GET_LIST_SUCCESS:
      case constants.GET_LIST_FAILED:
        draft[action.params?.loaderKey || 'isLoading'] = false;
        break;
      case constants.LOAD_DETAILS:
        draft.detailsLoading = true;
        break;
      case constants.LOAD_DETAILS_SUCCESS:
      case constants.LOAD_DETAILS_FAILED:
        draft.detailsLoading = false;
        break;
      case constants.EDIT_FIELD:
        actionParamKeys.forEach(k => {
          draft[k] = action.params[k];
        });
        break;
      case constants.SAVE_ENTITY:
        draft[action.meta?.loaderKey || 'isSaving'] = true;
        break;
      case constants.SAVE_ENTITY_SUCCESS:
      case constants.SAVE_ENTITY_FAILED:
        draft[action.params?.loaderKey || 'isSaving'] = false;
        break;
      case constants.DELETE_ENTITY:
        draft[action.meta?.loaderKey || 'isDeleting'] = true;
        break;
      case constants.DELETE_ENTITY_SUCCESS:
      case constants.DELETE_ENTITY_FAILED:
        draft[action.params?.loaderKey || 'isDeleting'] = false;
        break;
      case constants.GET_ORDERS:
        draft.isLoading = true;
        draft[action.meta?.loaderKey || 'isLoading'] = true;
        break;
      case constants.GET_ORDERS_SUCCESS:
      case constants.GET_ORDERS_FAILED:
        draft.isLoading = false;
        draft[action.params?.loaderKey || 'isLoading'] = false;
        break;

      case constants.UPDATE_SELECTED_ORDER:
        draft.selectedOrder = action.params.orders?.find(
          o => o.id === draft.selectedOroder?.id,
        );
        break;
      default:
        break;
    }
  });

export default oniReducer;
