import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCInput, SCSelect } from 'rollup-allspark';
import SCInputWithPrefixSuffix from '../../../../components/SCInputWithPrefixSuffix';
import { makeSelectProperty } from '../../selector';

const ModifierRow = ({
  index = null,
  currencySymbol = '',
  modifier = {},
  modifierList = [],
  modifiers = [],
  handleModifierChange,
}) => {
  const { title = '', unit_price: unitPrice = 0, quantity = 0 } = modifier;

  const handleChange = e => {
    const { name, value } = e.target;

    const newModifiers = [...modifiers];
    newModifiers[index] = {
      ...newModifiers[index],
      [name]: value,
    };
    handleModifierChange(newModifiers);
  };

  const handleRemove = () => {
    const newModifiers = modifiers.filter((_, i) => i !== index);
    handleModifierChange(newModifiers);
  };

  const onModifierChange = m => {
    const newModifiers = [...modifiers];
    newModifiers[index] = {
      ...newModifiers[index],
      id: m.id,
      title: m.title,
    };

    handleModifierChange(newModifiers);
  };

  return (
    <div className="flex items-center justify-between gap-x-4">
      <div className="flex flex-1 items-center gap-x-4">
        <SCSelect
          title={<div className="text-grey-darker -mt-2">Modifier</div>}
          searchable
          options={modifierList}
          track-by="id"
          customLabel={i => `${i.id}. ${i?.title?.en || i?.title}`}
          value={modifier}
          searchAbleAttribute={['id', 'title_en']}
          onSelect={onModifierChange}
          placeholder="Select Item"
          className="w-3/5"
        />
        <div className="sc-input-container w-1/5">
          <label htmlFor="unit_price">Unit Price</label>
          <SCInputWithPrefixSuffix
            prefix={currencySymbol}
            type="number"
            placeholder="Unit Price"
            value={unitPrice}
            name="unit_price"
            id="unit_price"
            handleChange={handleChange}
            className="border-grey-dark rounded-lg mt-1"
          />
        </div>
        <SCInput
          name="quantity"
          type="number"
          label="Count"
          className="w-1/5"
          placeholder="Quantity"
          inputClass="border rounded-lg"
          value={quantity}
          handleChange={handleChange}
        />
      </div>
      <button
        type="button"
        onClick={handleRemove}
        className="flex items-center justify-end gap-x-1 p-1"
      >
        <i className="kt-x text-red-dark text-sm" />
        <p className="text-xs text-red-dark font-medium line-clamp-1">
          Remove Modifier
        </p>
      </button>
    </div>
  );
};

ModifierRow.propTypes = {
  index: PropTypes.number,
  currencySymbol: PropTypes.string,
  modifier: PropTypes.object,
  modifiers: PropTypes.array,
  handleModifierChange: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  cartItems: makeSelectProperty('cartItems'),
  itemList: makeSelectProperty('items'),
  modifierList: makeSelectProperty('modifiers'),
  selectedBranch: makeSelectProperty('selectedBranch'),
});

const mapDispatchToProps = dispatch => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ModifierRow);
