import PropTypes from 'prop-types';
import React from 'react';
import Chart from 'react-apexcharts';
import { ORDER_COUNT_KEYS } from '../../config/constants';
import {
  ORDER_CANCELLATION_RATE_KEY,
  SUMMARY_CHART_BREAKPOINTS,
} from '../../config/constants/analytics';
import { isFunction, isNumber } from '../../utils';
import { abbreviateNumber, readablePrice } from '../../utils/getCurrency';

const SummaryCard = ({
  title,
  count,
  chartLabel,
  chartColors,
  chartWidth = '300',
  currencyObj,
  chartKey,
  totalFormatter,
}) => {
  const isOrderCountChart = Object.values(ORDER_COUNT_KEYS).includes(chartKey);
  const isCancellationRateChart = chartKey === ORDER_CANCELLATION_RATE_KEY;

  // Helper function to format tooltip value
  const formatTooltipValue = value => {
    const numberVal = parseFloat(value, 10);

    if (!isNumber(numberVal)) return value;
    if (isCancellationRateChart) return `${numberVal.toFixed(2)}%`;
    if (isOrderCountChart) return numberVal;

    return readablePrice(numberVal * 100, currencyObj);
  };

  // Helper function to format chart label value
  const formatLabelValue = val => {
    const numberVal = parseFloat(val, 10);

    if (!isNumber(numberVal)) return val;
    if (isCancellationRateChart) return `${numberVal.toFixed(2)}%`; // Format as percentage

    return numberVal;
  };

  // Helper function to format total value
  const formatTotalValue = w => {
    const total = w.globals.seriesTotals.reduce((a, b) => +a + +b, 0);
    const numberVal = parseFloat(total);

    if (!isNumber(numberVal)) return total;

    if (isCancellationRateChart) {
      return `${numberVal.toFixed(2)}%`; // Format as percentage
    }
    if (isOrderCountChart) return numberVal;

    return `${currencyObj.symbol || '$'}${abbreviateNumber(numberVal)}`;
  };

  const chartConfig = {
    options: {
      tooltip: {
        y: {
          formatter: formatTooltipValue,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '80%',
            labels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
                formatter: formatLabelValue,
              },
              total: {
                show: true,
                showAlways: true,
                formatter: isFunction(totalFormatter)
                  ? totalFormatter
                  : formatTotalValue,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: chartColors,
      states: {
        hover: {
          filter: {
            type: 'lighten',
            value: 0.1,
          },
        },
      },
      stroke: {
        show: false,
      },
      legend: {
        show: false,
      },
      labels: chartLabel,
      chart: {
        type: 'donut',
        width: chartWidth,
        animation: {
          enabled: false,
          animateGradually: {
            enabled: false,
            delay: 0,
          },
          dynamicAnimation: {
            enabled: false,
            speed: 0,
          },
        },
        nodata: {
          text: 'No data',
          style: {
            color: '#000000',
            fontSize: '16px',
          },
        },
      },
      responsive: SUMMARY_CHART_BREAKPOINTS,
    },
    series: count,
  };

  const renderNoData = (
    <div className="flex justify-center items-center text-grey-darker absolute m-auto left-0 right-0 top-0 mt-5">
      No Data Found!
    </div>
  );
  return (
    <div className="flex flex-col items-center mt-4 lg:mb-4 md:mb-0">
      <div className="text-sm text-primary mb-4"> {title}</div>
      <div className="relative">
        <Chart
          options={chartConfig.options}
          series={chartConfig.series}
          type="donut"
          width={chartWidth}
        />
        {count && count.length ? null : renderNoData}
      </div>
    </div>
  );
};

SummaryCard.propTypes = {
  title: PropTypes.string,
  count: PropTypes.array,
  chartLabel: PropTypes.array,
  chartWidth: PropTypes.string,
  chartColors: PropTypes.array,
  currencyObj: PropTypes.object,
  chartKey: PropTypes.string,
  totalFormatter: PropTypes.array,
};

export default SummaryCard;
