import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCButton, SCInput, SCModal, SCModalBody } from 'rollup-allspark';
import { isFunction, isNumber } from '../../../utils';
import { updateEntity } from '../actions';
import { makeSelectProperty } from '../selector';

const DeliveryFee = ({
  deliveryFee = 0,
  isOpen = false,
  onClose = () => {},
  updateField = () => {},
}) => {
  const ref = useRef(null);
  const [deliveryFeeAmount, setDeliveryFeeAmount] = useState(0);

  useEffect(() => {
    if (isOpen) {
      ref.current.showModal();
    } else {
      ref.current.closeModal();
    }
  }, [isOpen]);

  useEffect(() => {
    setDeliveryFeeAmount(deliveryFee);
  }, [deliveryFee]);

  const onModalClose = () => {
    if (isFunction(onClose)) {
      onClose();
    }
  };

  const onConfirm = () => {
    updateField({
      deliveryFee: deliveryFeeAmount,
    });

    onModalClose();
  };

  const body = (
    <SCModalBody>
      <div className="flex flex-col gap-y-4 normal-case">
        <div className="text-base font-medium text-black">Delivery Fee</div>
        <SCInput
          type="number"
          inputClass="border rounded-lg scam-business-name"
          value={deliveryFeeAmount}
          handleChange={e =>
            setDeliveryFeeAmount(parseFloat(e.target.value) || 0)
          }
          hasError={!isNumber(deliveryFeeAmount)}
          hint={
            !isNumber(deliveryFeeAmount)
              ? 'Please enter a valid Delivery Fee'
              : ''
          }
        />
        <div className="pt-8 flex gap-x-4 justify-end">
          <SCButton variant="danger-outline" action={onModalClose}>
            Cancel
          </SCButton>
          <SCButton action={onConfirm}>Confirm</SCButton>
        </div>
      </div>
    </SCModalBody>
  );

  return (
    <SCModal
      ref={ref}
      variant="white"
      body={body}
      size="lg"
      onModalClose={onModalClose}
    />
  );
};

DeliveryFee.propTypes = {
  deliveryFee: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  updateField: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  deliveryFee: makeSelectProperty('deliveryFee'),
});

const mapDispatchToProps = dispatch => ({
  updateField: val => dispatch(updateEntity(val)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DeliveryFee);
