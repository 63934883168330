import { set } from 'date-fns/esm';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCButton } from 'rollup-allspark';
import { ORDER_STATUS } from '../../config';
import {
  ORDER_STATUS_LIST,
  ORDER_TYPE_LIST,
} from '../../config/constants/orders';
import { isEmpty, isNumber } from '../../utils';
import { readablePrice } from '../../utils/getCurrency';
import { getCartInfo } from '../../utils/orderAmender';
import { saveEntity, updateEntity } from './actions';
import DeliveryFeeModal from './Modals/DeliveryFee';
import DiscountModal from './Modals/Discount';
import VatModal from './Modals/VAT';
import { AMEND_ORDER } from './sagaMap';
import { makeSelectProperty } from './selector';

const Summary = ({
  amendOrder = () => {},
  cartItems = [],
  discountAmount,
  deliveryFee,
  externalId,
  paymentStatus,
  selectedBrand,
  selectedBranch,
  selectedBusiness,
  selectedDate,
  selectedPaymentChannel,
  selectedProvider,
  selectedTime,
  shortId,
  status,
  type,
  updateField,
  vatAmount,
}) => {
  const [isShowingVatModal, setIsShowingVatModal] = useState(false);
  const [isShowingDiscountModal, setIsShowingDiscountModal] = useState(false);
  const [isShowingDeliveryFeeModal, setIsShowingDeliveryFeeModal] =
    useState(false);

  const currency = {
    code: selectedBranch?.currency_code,
    symbol: selectedBranch?.currency_symbol,
  };

  const canAmendOrder = (() => {
    if (
      isEmpty(externalId) ||
      isEmpty(shortId) ||
      !selectedDate ||
      isEmpty(selectedProvider)
    )
      return false;

    const invalid = cartItems.find(i => {
      const missingTitle = isEmpty(i.title);
      const invalidUnitPrice =
        isEmpty(i.unit_price) ||
        !isNumber(parseFloat(i.unit_price)) ||
        parseFloat(i.unit_price) <= 0;
      const invalidQuantity =
        isEmpty(i.quantity) ||
        !isNumber(parseFloat(i.quantity)) ||
        parseFloat(i.quantity) <= 0;

      const invalidMod = i.modifiers.find(m => {
        const missingModTitle = isEmpty(m.title);
        const invalidModQuantity =
          isEmpty(m.quantity) ||
          !isNumber(parseFloat(m.quantity)) ||
          parseFloat(m.quantity) <= 0;

        const invalidModUnitPrice =
          !isEmpty(m.unit_price) &&
          (!isNumber(parseFloat(m.unit_price)) || parseFloat(m.unit_price) < 0);

        return missingModTitle || invalidModUnitPrice || invalidModQuantity;
      });

      return missingTitle || invalidUnitPrice || invalidQuantity || invalidMod;
    });

    return isEmpty(invalid);
  })();

  const total = (() => {
    const subTotal = cartItems?.reduce((sum, item) => {
      const { total_price: totalPrice = 0 } = item;

      return sum + parseFloat(totalPrice);
    }, 0);

    return {
      subTotal,
      grandTotal: subTotal + deliveryFee + vatAmount - discountAmount,
    };
  })();

  const onAmendOrderClick = () => {
    if (!canAmendOrder) return;

    const orderTime = set(new Date(selectedDate), {
      hours: parseFloat(selectedTime) / 100,
      minutes: parseFloat(selectedTime) % 100,
      seconds: 0,
    });

    const { cart, totalItems, uniqueItems } = getCartInfo(
      cartItems,
      selectedProvider,
      selectedBrand,
    );

    const data = {
      additional_fee: 0,
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.title,
      branch_id: selectedBranch?.id,
      business_id: selectedBusiness?.id,
      cart,
      currency: selectedBranch?.currency_code,
      currency_id: selectedBranch?.currency_id,
      currency_symbol: selectedBranch?.currency_symbol,
      delivery_fee: deliveryFee,
      discount_amount: discountAmount,
      external_id: externalId,
      is_amended_order: true,
      is_rounding_off_applicable: false,
      menu_version: 2,
      order_time: orderTime.toISOString(),
      payment_channel_id: selectedPaymentChannel?.id,
      payment_method: selectedPaymentChannel?.payment_method_id,
      payment_status: paymentStatus?.id,
      provider_id: selectedProvider?.id,
      restaurant_service_fee: 0,
      round_off_amount: 0,
      short_id: shortId,
      status: status?.id,
      total_items: totalItems,
      type: type?.id,
      unique_items: uniqueItems,
      vat_amount: vatAmount,
    };

    amendOrder({
      ...AMEND_ORDER,
      data,
      callbackFn: resp => {
        updateField({
          cartItems: [],
          deliveryFee: 0,
          discountAmount: 0,
          externalId: '',
          selectedDate: null,
          status: ORDER_STATUS_LIST.find(s => s.id === ORDER_STATUS.DELIVERED),
          type: ORDER_TYPE_LIST[0],
          vatAmount: 0,
        });
      },
    });
  };

  const totalEl = !isEmpty(cartItems) ? (
    <div className="flex flex-col gap-y-4 bg-white p-4 rounded-lg border border-grey">
      <div className="flex items-center justify-between py-2 text-base">
        <div className="flex flex-1 gap-x-2">
          <div className="flex font-medium">Total </div>
          <span className="text-grey-darker">(incl. VAT)</span>
        </div>
        <div className="font-medium">
          {readablePrice(total.grandTotal * 100, currency)}
        </div>
      </div>
      <SCButton
        className="w-full"
        disabled={!canAmendOrder}
        action={onAmendOrderClick}
      >
        Amend Order
      </SCButton>
    </div>
  ) : null;

  return (
    <>
      <div className="bg-white px-4 py-6 rounded-lg border border-grey gap-y-4">
        <div className="flex items-center font-medium justify-between text-base pb-4">
          <div>Subtotal</div>
          <div>{readablePrice(total.subTotal * 100, currency)}</div>
        </div>
        <div className="flex flex-col gap-y-2 py-4 border-y border-grey">
          <div className="flex justify-between items-center">
            <div>VAT</div>
            <div className="flex">
              <div className="flex items-center">
                {readablePrice(vatAmount * 100, currency)}
              </div>
              <div
                role="presentation"
                className="flex items-center justify-center w-6 h-6 cursor-pointer"
                onClick={() => setIsShowingVatModal(true)}
              >
                <i className="kt-plus text-grey-darker text-base" />
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div>Discount</div>
            <div className="flex">
              <div className="flex items-center">
                {readablePrice(discountAmount * 100, currency)}
              </div>
              <div
                role="presentation"
                className="flex items-center justify-center w-6 h-6 cursor-pointer"
                onClick={() => setIsShowingDiscountModal(true)}
              >
                <i className="kt-plus text-grey-darker text-base" />
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div>Delivery Fee</div>
            <div className="flex">
              <div className="flex items-center">
                {readablePrice(deliveryFee * 100, currency)}
              </div>
              <div
                role="presentation"
                className="flex items-center justify-center w-6 h-6 cursor-pointer"
                onClick={() => setIsShowingDeliveryFeeModal(true)}
              >
                <i className="kt-plus text-grey-darker text-base" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center font-medium justify-between text-base pt-4">
          <div>Total</div>
          <div>{readablePrice(total.grandTotal * 100, currency)}</div>
        </div>
        <VatModal
          isOpen={isShowingVatModal}
          onClose={() => setIsShowingVatModal(false)}
        />
        <DiscountModal
          isOpen={isShowingDiscountModal}
          onClose={() => setIsShowingDiscountModal(false)}
        />
        <DeliveryFeeModal
          isOpen={isShowingDeliveryFeeModal}
          onClose={() => setIsShowingDeliveryFeeModal(false)}
        />
      </div>
      {totalEl}
    </>
  );
};

Summary.propTypes = {
  amendOrder: PropTypes.func,
  cartItems: PropTypes.array,
  discountAmount: PropTypes.number,
  deliveryFee: PropTypes.number,
  externalId: PropTypes.string,
  paymentStatus: PropTypes.object,
  selectedBranch: PropTypes.object,
  selectedBrand: PropTypes.object,
  selectedBusiness: PropTypes.object,
  selectedDate: PropTypes.object,
  selectedPaymentChannel: PropTypes.object,
  selectedProvider: PropTypes.object,
  selectedTime: PropTypes.number,
  shortId: PropTypes.string,
  status: PropTypes.object,
  type: PropTypes.object,
  updateField: PropTypes.func,
  vatAmount: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  cartItems: makeSelectProperty('cartItems'),
  discountAmount: makeSelectProperty('discountAmount'),
  deliveryFee: makeSelectProperty('deliveryFee'),
  externalId: makeSelectProperty('externalId'),
  paymentStatus: makeSelectProperty('paymentStatus'),
  selectedBranch: makeSelectProperty('selectedBranch'),
  selectedBrand: makeSelectProperty('selectedBrand'),
  selectedBusiness: makeSelectProperty('selectedBusiness'),
  selectedDate: makeSelectProperty('selectedDate'),
  selectedPaymentChannel: makeSelectProperty('selectedPaymentChannel'),
  selectedProvider: makeSelectProperty('selectedProvider'),
  selectedTime: makeSelectProperty('selectedTime'),
  shortId: makeSelectProperty('shortId'),
  status: makeSelectProperty('status'),
  type: makeSelectProperty('type'),
  vatAmount: makeSelectProperty('vatAmount'),
});

const mapDispatchToProps = dispatch => ({
  amendOrder: val => dispatch(saveEntity(val)),
  updateField: val => dispatch(updateEntity(val)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Summary);
