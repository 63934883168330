import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import ComponentPlaceholder from '../../components/ComponentPlaceholder';
import RouteChildViewer from '../../components/Layout/RouteChildViewer';
import {
  PAYMENT_STATUS_PAID_ID,
  PAYMENT_STATUS_REFUND_FAILED,
  PAYMENT_STATUS_REFUND_REQUESTED_FAILED_ID,
  PAYMENT_STATUS_REFUND_REQUESTED_ID,
  PAYMENT_STATUS_REFUNDED_ID,
} from '../../config/constants/orders';
import { isEmpty } from '../../utils';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import Cart from './Cart';
import Filters from './Filters';
import OrderInfo from './OrderInfo';
import OrderSummary from './Summary';
import { getList, updateEntity } from './actions';
import reducer from './reducer';
import saga from './saga';
import {
  PAYMENT_CHANNEL_LIST,
  PAYMENT_STATUS_LIST,
  STORE_ITEMS,
} from './sagaMap';
import { makeSelectProperty } from './selector';

const OrderAmender = ({
  cartItems = [],
  getItems = () => {},
  getPaymentStatusList = () => {},
  getPaymentChannels = () => {},
  selectedBranch = {},
  selectedBrand = {},
  selectedBusiness = {},
  updateField = () => {},
}) => {
  useInjectReducer({ key: 'orderAmender', reducer });
  useInjectSaga({ key: 'orderAmender', saga });

  useEffect(() => {
    getPaymentStatusList({
      ...PAYMENT_STATUS_LIST,
      callbackFn: (l = []) =>
        updateField({
          paymentStatus: l.find(ps => ps.id === PAYMENT_STATUS_PAID_ID),
          paymentStatusList: l.filter(
            ps =>
              ![
                PAYMENT_STATUS_REFUNDED_ID,
                PAYMENT_STATUS_REFUND_REQUESTED_ID,
                PAYMENT_STATUS_REFUND_REQUESTED_FAILED_ID,
                PAYMENT_STATUS_REFUND_FAILED,
              ].includes(ps.id),
          ),
        }),
    });

    getPaymentChannels({
      ...PAYMENT_CHANNEL_LIST,
      callbackFn: (l = []) => {
        if (!isEmpty(l)) {
          updateField({
            selectedPaymentChannel: l[0],
          });
        }
      },
    });
  }, []);

  useEffect(() => {
    if (
      isEmpty(selectedBranch) ||
      isEmpty(selectedBrand) ||
      isEmpty(selectedBusiness)
    ) {
      return;
    }

    getItems({
      ...STORE_ITEMS,
      params: {
        businessID: selectedBusiness?.id,
        brandID: selectedBrand?.id,
        branchID: selectedBranch?.id,
      },
      callbackFn: l => {
        const itemList = l.map(i => ({ ...i, title_en: i.title.en }));
        const onlyModifiers = l.reduce((acc, i) => {
          if (i.onlyModifier) {
            acc.push({
              ...i,
              title_en: i.title.en,
            });
          }
          return acc;
        }, []);

        updateField({
          items: itemList,
          modifiers: onlyModifiers,
        });
      },
    });
  }, [selectedBranch, selectedBrand, selectedBusiness]);

  const cartEl =
    isEmpty(selectedBusiness) ||
    isEmpty(selectedBranch) ||
    isEmpty(selectedBrand) ? (
      <ComponentPlaceholder label="Please select business, branch, and brand first to proceed" />
    ) : (
      <Cart />
    );
  const infoEl = !isEmpty(cartItems) ? <OrderInfo /> : null;
  const calcEl = !isEmpty(cartItems) ? <OrderSummary /> : null;

  return (
    <RouteChildViewer headerTitle="Order Amender">
      <Helmet>
        <title>Order Amender</title>
        <meta name="Order Amender" content="This is order amender page" />
      </Helmet>
      <div className="flex gap-x-8 w-full">
        <div className="flex flex-col gap-y-4 w-2/3">
          <Filters />
          {cartEl}
        </div>
        <div className="flex flex-col gap-y-4 w-1/3">
          {infoEl}
          {calcEl}
        </div>
      </div>
    </RouteChildViewer>
  );
};

OrderAmender.propTypes = {
  cartItems: PropTypes.array,
  getItems: PropTypes.func,
  getPaymentStatusList: PropTypes.func,
  getPaymentChannels: PropTypes.func,
  selectedBranch: PropTypes.object,
  selectedBrand: PropTypes.object,
  selectedBusiness: PropTypes.object,
  updateField: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  cartItems: makeSelectProperty('cartItems'),
  selectedBranch: makeSelectProperty('selectedBranch'),
  selectedBrand: makeSelectProperty('selectedBrand'),
  selectedBusiness: makeSelectProperty('selectedBusiness'),
});

const mapDispatchToProps = dispatch => ({
  getItems: meta => dispatch(getList(meta)),
  getPaymentStatusList: meta => dispatch(getList(meta)),
  getPaymentChannels: meta => dispatch(getList(meta)),
  updateField: meta => dispatch(updateEntity(meta)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrderAmender);
