import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCButton, SCLoader } from 'rollup-allspark';
import HelpPopover from '../../../../components/HelpPopover';
import {
  PAYMENT_METHOD_BASKET_SIZE_HELP,
  PAYMENT_METHOD_DISCOUNT_HELP,
  PAYMENT_METHOD_GROSS_REVENUE_HELP,
  PAYMENT_METHOD_HELP,
} from '../../../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import { cn, isEmpty } from '../../../../utils';
import { readablePrice } from '../../../../utils/getCurrency';
import {
  generatePaymentMethodCSVData,
  generateXLSXDataFromCSVData,
  getFlatPaymentMethodData,
  sortAggregatedDataRow,
} from '../../../../utils/nativeAnalyticsUtils';
import {
  downloadCsvFromJSON,
  downloadXLSXFromJSON,
} from '../../../../utils/sheetUtils';
import {
  makeSelectPaymentMethodLoading,
  makeSelectProperty,
} from '../../selectors';

const PaymentMethodTable = ({
  selectedComparisonType,
  paymentMethodAnalytics,
  PaymentMethodTableLoading,
}) => {
  // CONSTANTS
  const headerKeys = [
    {
      title: 'Payment Method',
      align: 'left',
      key: 'payment_method',
    },
    {
      title: 'Payment Channel',
      align: 'left',
      key: 'payment_channel',
    },
    {
      title: 'No of Orders',
      align: 'right',
      key: 'total_orders',
    },
    {
      title: 'Gross Revenue',
      align: 'right',
      helpText: PAYMENT_METHOD_GROSS_REVENUE_HELP,
      key: 'gross_revenue',
    },
    {
      title: 'Discount',
      align: 'right',
      helpText: PAYMENT_METHOD_DISCOUNT_HELP,
      key: 'discount',
    },
    {
      title: 'AVG Basket Size',
      align: 'right',
      helpText: PAYMENT_METHOD_BASKET_SIZE_HELP,
      key: 'avg_basket_size',
    },
  ];

  // HOOKS
  const [isPaymentPopoverOpen, setIsPaymentPopoverOpen] = useState(false);
  const [popoverOpenStates, setPopoverOpenStates] = useState(
    Array(headerKeys.length).fill(false),
  );
  const [sortOrder, setSortOrder] = useState(null);
  const [sortBy, setSortBy] = useState(null);

  const tableRowData = useMemo(() => {
    if (isEmpty(paymentMethodAnalytics)) return [];
    const analytics = getFlatPaymentMethodData(
      paymentMethodAnalytics,
      selectedComparisonType,
    );
    return sortAggregatedDataRow(analytics, sortBy, sortOrder);
  }, [paymentMethodAnalytics, selectedComparisonType, sortOrder, sortBy]);

  // HANDLERS AND GENERATORS
  const csvHeaderData = {
    payment_method: 'Payment Method',
    total_orders: 'No of Orders',
    gross_revenue: 'Gross Revenue',
    gross_revenue_cent: 'Gross Revenue (In Cents)',
    discount: 'Discount',
    discount_cent: 'Discount (In Cents)',
    avg_basket_size: 'AVG Basket Size',
    avg_basket_size_cent: 'AVG Basket Size (In Cents)',
  };
  const handleCSVDownload = () => {
    const CSVData = generatePaymentMethodCSVData(csvHeaderData, tableRowData);

    downloadCsvFromJSON(
      CSVData,
      `payment-method-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  const handleXLSXDownload = () => {
    const CSVData = generatePaymentMethodCSVData(csvHeaderData, tableRowData);
    const XLSXData = generateXLSXDataFromCSVData(CSVData);

    downloadXLSXFromJSON(
      XLSXData,
      `payment-method-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  const handleSorting = newSortBy => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(newSortBy);
  };

  const getSortClass = field => {
    if (sortBy === field) {
      return `cursor-pointer ${
        sortOrder === 'asc' ? 'sc-keyboard-arrow-up' : 'sc-keyboard-arrow-down'
      }`;
    }

    return `cursor-pointer sc-sort`;
  };

  // actionButton
  const renderDownloadCSVButton = !isEmpty(tableRowData) ? (
    <div className="flex items-center gap-x-2">
      <SCButton
        variant="secondary"
        size="sm"
        className="rounded-lg bg-grey-light text-black hover:bg-grey gap-x-1 w-auto flex items-center px-4 h-6 text-xs font-medium"
        action={handleCSVDownload}
      >
        <i className="kt-download" />
        <p>CSV</p>
      </SCButton>
      <SCButton
        variant="secondary"
        size="sm"
        className="rounded-lg bg-grey-light text-black hover:bg-grey gap-x-1 w-auto flex items-center px-4 h-6 text-xs font-medium"
        action={handleXLSXDownload}
      >
        <i className="kt-download" />
        <p>XLSX</p>
      </SCButton>
    </div>
  ) : null;

  // loaders
  const renderLoader = (
    <div className="flex items-center justify-center h-48 w-full">
      <SCLoader lineWidth={5} diameter={50} />
    </div>
  );

  // tableBody

  const headerEl = (() => {
    const baseClass = 'bg-grey-lighter capitalize p-4 flex flex-1 items-center';

    const headerCells = headerKeys.map((cell, index) => {
      const alignClass = `text-${cell.align}`;
      const tooltipEl = !isEmpty(cell.helpText) ? (
        <HelpPopover
          isPopoverOpen={popoverOpenStates[index]}
          position={cell.key === 'avg_basket_size' ? 'top' : 'right'}
          isHtmlElement
          content={cell.helpText}
          setIsPopoverOpen={newState => {
            // Update the correct state in the array
            const newPopoverOpenStates = [...popoverOpenStates];
            newPopoverOpenStates[index] = newState;
            setPopoverOpenStates(newPopoverOpenStates);
          }}
          iconClassNames="sc-information cursor-pointer ml-1"
        />
      ) : null;

      const sortEl = cell.key ? (
        <button
          type="button"
          className={getSortClass(cell.key)}
          onClick={() => handleSorting(cell.key)}
          aria-label={`Sort by ${cell.title}`}
        />
      ) : null;

      return (
        <div
          key={`discount-header-cell-${index + 1}`}
          className={cn(baseClass, alignClass)}
        >
          <div className="flex w-full justify-between items-center gap-x-2">
            <div className="flex items-center gap-x-1">
              {cell.title}
              {tooltipEl}
            </div>
            {sortEl}
          </div>
        </div>
      );
    });

    return (
      <div key="analytics-header" className="flex w-full">
        {headerCells}
      </div>
    );
  })();

  const totalEl = (() => {
    if (isEmpty(tableRowData)) return null;

    let totalOrders = 0;
    let totalGrossRevenue = 0;
    let totalDiscount = 0;
    let totalAvgBasketSize = 0;

    tableRowData.forEach(cell => {
      totalOrders += cell.total_orders;
      totalGrossRevenue += cell.gross_revenue;
      totalDiscount += cell.discount;
      totalAvgBasketSize += cell.avg_basket_size / tableRowData.length;
    });

    const currency = !isEmpty(tableRowData[0].currency)
      ? {
          code: tableRowData[0].currency,
          symbol: tableRowData[0].currency_symbol,
        }
      : null;

    return (
      <div key="analytics-total" className="flex w-full font-medium">
        <div className="p-4 flex-1">Grand total</div>
        <div className="p-4 flex-1" />
        <div className="p-4 flex-1 text-right">{totalOrders}</div>
        <div className="p-4 flex-1 text-right">
          {readablePrice(totalGrossRevenue, currency)}
        </div>
        <div className="p-4 flex-1 text-right">
          {readablePrice(totalDiscount, currency)}
        </div>
        <div className="p-4 flex-1 text-right">
          {readablePrice(totalAvgBasketSize, currency)}
        </div>
      </div>
    );
  })();

  const flatRowEl = (() =>
    tableRowData.map(rowData => {
      const currency = !isEmpty(rowData.currency)
        ? {
            code: rowData.currency,
            symbol: rowData.currency_symbol,
          }
        : null;

      return (
        <div
          key={`${rowData.provider_id}-${rowData.payment_channel}-${rowData.order_source}`}
          className="flex w-full divide-x divide-grey"
        >
          <div className="p-4 flex-1 text-left">{rowData.payment_method}</div>
          <div className="p-4 flex-1 text-left">{rowData.payment_channel}</div>
          <div className="p-4 flex-1 text-right">{rowData.total_orders}</div>
          <div className="p-4 flex-1 text-right">
            {readablePrice(rowData.gross_revenue, currency)}
          </div>
          <div className="p-4 flex-1 text-right">
            {readablePrice(rowData.discount, currency)}
          </div>
          <div className="p-4 flex-1 text-right">
            {readablePrice(rowData.avg_basket_size, currency)}
          </div>
        </div>
      );
    }))();

  const tableEl = (() => {
    const els = [headerEl];

    if (isEmpty(tableRowData)) {
      els.push(
        <div
          key="analytics-no-data"
          className="text-center p-5 text-2xl text-grey-darker h-20 flex items-center justify-center"
        >
          No data Found
        </div>,
      );
    } else {
      els.push(flatRowEl);
      els.push(totalEl);
    }
    return els;
  })();

  return (
    <div className="w-full flex flex-col bg-white rounded gap-y-4">
      <div className="flex justify-between px-4 pt-4">
        <div className="flex gap-x-2 h-full items-center">
          <div className="text-base font-medium capitalize">
            Payment Method Analytics
          </div>
          <HelpPopover
            isPopoverOpen={isPaymentPopoverOpen}
            position="right"
            isHtmlElement
            content={PAYMENT_METHOD_HELP}
            setIsPopoverOpen={setIsPaymentPopoverOpen}
            iconClassNames="sc-information cursor-pointer"
          />
        </div>
        {renderDownloadCSVButton}
      </div>
      <div className="flex flex-col items-center divide-y divide-grey">
        {PaymentMethodTableLoading ? renderLoader : tableEl}
      </div>
    </div>
  );
};

PaymentMethodTable.propTypes = {
  paymentMethodAnalytics: PropTypes.array,
  PaymentMethodTableLoading: PropTypes.bool,
  selectedComparisonType: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  paymentMethodAnalytics: makeSelectProperty('paymentMethodAnalytics'),
  PaymentMethodTableLoading: makeSelectPaymentMethodLoading(),
  providers: makeSelectProperty('analyticsProviders'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PaymentMethodTable);
