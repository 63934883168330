import { isEmpty } from '.';

export const getCartInfo = (cartItems, selectedProvider, selectedBrand) => {
  let totalItems = 0;

  const cart = cartItems.map(ci => {
    totalItems += parseFloat(ci.quantity);

    const modifiers = !isEmpty(ci.modifiers)
      ? ci.modifiers.map(m => ({
          id: m?.id,
          is_selected: true,
          extra_price: parseFloat(m?.unit_price) || 0,
          modifier_id: m?.id,
          modifier_quantity: parseFloat(m?.quantity) || 1,
          title: m?.title.en,
        }))
      : [];

    const groups = isEmpty(modifiers) ? [] : [{ modifiers }];

    return {
      id: ci.id,
      prices: [
        {
          provider_id: selectedProvider?.id,
          code: 'PHP',
          price: parseFloat(ci.unit_price),
        },
      ],
      vat: 0,
      item_id: ci.id,
      title: ci.title,
      cart_id: `${ci.id}`,
      unit_price: parseFloat(ci.unit_price),
      groups,
      quantity: parseFloat(ci.quantity),
      brand: {
        id: selectedBrand?.id,
        logo: selectedBrand?.logo,
        title: selectedBrand?.title,
      },
    };
  });

  return { cart, totalItems, uniqueItems: cartItems.length };
};
