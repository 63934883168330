import { format } from 'date-fns/esm';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCButton } from 'rollup-allspark';
import ComponentPlaceholder from '../../../components/ComponentPlaceholder';
import { MULTI_LANG_TEXTS } from '../../../config/constants/translation-strings/analytics';
import { isEmpty } from '../../../utils';
import { readablePrice } from '../../../utils/getCurrency';
import {
  generatePromoAnalyticsCSVData,
  generateXLSXDataFromCSVData,
} from '../../../utils/nativeAnalyticsUtils';
import {
  downloadCsvFromJSON,
  downloadXLSXFromJSON,
} from '../../../utils/sheetUtils';
import { makeSelectProperty } from '../selectors';

const PromoAnalytics = ({ promoSummary = {}, promoAnalytics = [] }) => {
  const { PROMO } = MULTI_LANG_TEXTS || {};
  const {
    PROMO_SUMMARY_TOTAL_PROMOS,
    PROMO_SUMMARY_ACTIVE_PROMOS,
    PROMO_TITLE,
    PROMO_TABLE_HEADER_NAME,
    PROMO_TABLE_HEADER_NET_REVENUE,
    PROMO_TABLE_HEADER_DISCOUNT_APPLIED,
    PROMO_TABLE_HEADER_TRANSATION_COUNT,
    PROMO_TABLE_HEADER_USERS_CLAIMED,
  } = PROMO || {};

  const csvHeaderData = {
    promo_code: 'Promo Name',
    gross_revenue: 'Gross Order Value / Net Sales',
    gross_revenue_cent: 'Gross Order Value / Net Sales (Cent)',
    discount: 'Discount Applied',
    discount_cent: 'Discount Applied (Cent)',
    total_orders: 'Total Orders',
    total_users: 'Users Claimed',
  };

  const handleCSVDownload = () => {
    const CSVData = generatePromoAnalyticsCSVData(
      csvHeaderData,
      promoAnalytics,
    );

    downloadCsvFromJSON(
      CSVData,
      `Promo-Analytics-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  const handleXLSXDownload = () => {
    const CSVData = generatePromoAnalyticsCSVData(
      csvHeaderData,
      promoAnalytics,
    );
    const XLSXData = generateXLSXDataFromCSVData(CSVData);

    downloadXLSXFromJSON(
      XLSXData,
      `Promo-Analytics-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  const summaryEl = isEmpty(promoSummary) ? null : (
    <div className="flex gap-x-4 p-4">
      <div className="w-1/2 p-4 border border-grey rounded-lg bg-grey-lighter flex flex-col gap-y-3">
        <div className="text-xs">{PROMO_SUMMARY_TOTAL_PROMOS}</div>
        <div className="font-medium text-2xl">{promoSummary?.total_promos}</div>
      </div>
      <div className="w-1/2 p-4 border border-green-lighter rounded-lg bg-green-lighter text-green-dark flex flex-col gap-y-3">
        <div className="text-xs">{PROMO_SUMMARY_ACTIVE_PROMOS}</div>
        <div className="font-medium text-2xl">
          {promoSummary?.active_promos}
        </div>
      </div>
    </div>
  );

  const listEl = (() => {
    if (isEmpty(promoAnalytics)) {
      return (
        <div className="pb-6">
          <ComponentPlaceholder label="No promo analytics found" />
        </div>
      );
    }

    const headerEl = (
      <div className="flex divide-x-1/2 divide-grey bg-grey-lighter font-medium border-t-1/2 border-grey">
        <div className="w-1/5 h-12 px-4 flex items-center">
          {PROMO_TABLE_HEADER_NAME}
        </div>
        <div className="w-1/5 h-12 px-4 flex items-center justify-end">
          {PROMO_TABLE_HEADER_NET_REVENUE}
        </div>
        <div className="w-1/5 h-12 px-4 flex items-center justify-end">
          {PROMO_TABLE_HEADER_DISCOUNT_APPLIED}
        </div>
        <div className="w-1/5 h-12 px-4 flex items-center justify-end">
          {PROMO_TABLE_HEADER_TRANSATION_COUNT}
        </div>
        <div className="w-1/5 h-12 px-4 flex items-center justify-end">
          {PROMO_TABLE_HEADER_USERS_CLAIMED}
        </div>
      </div>
    );

    const rowEl = promoAnalytics.map(promo => {
      const currency = {
        code: promo.currency,
        symbol: promo.currency_symbol,
      };

      return (
        <div key={promo.promo_id} className="flex divide-x-1/2 divide-grey">
          <div className="w-1/5 h-10 px-4 flex items-center">
            {promo.promo_code}
          </div>
          <div className="w-1/5 h-10 px-4 flex items-center justify-end">
            {readablePrice(promo.gross_revenue, currency)}
          </div>
          <div className="w-1/5 h-10 px-4 flex items-center justify-end">
            {readablePrice(promo.discount, currency)}
          </div>
          <div className="w-1/5 h-10 px-4 flex items-center justify-end">
            {promo.total_orders}
          </div>
          <div className="w-1/5 h-10 px-4 flex items-center justify-end">
            {promo.total_users}
          </div>
        </div>
      );
    });

    return (
      <div className="flex flex-col divide-y-1/2 divide-grey">
        {headerEl}
        {rowEl}
      </div>
    );
  })();

  const fileDownloadEl = !isEmpty(promoAnalytics) ? (
    <div className="flex items-center gap-x-2">
      <SCButton
        variant="primary-outline"
        size="sm"
        className="h-6 w-auto px-5 text-xs flex items-center gap-x-1"
        action={handleCSVDownload}
      >
        <i className="kt-download" />
        <p>CSV</p>
      </SCButton>
      <SCButton
        label="Download"
        variant="primary-outline"
        size="sm"
        className="h-6 w-auto px-5 text-xs flex items-center gap-x-1"
        action={handleXLSXDownload}
      >
        <i className="kt-download" />
        <p>XLSX</p>
      </SCButton>
    </div>
  ) : null;

  return (
    <div className="bg-white rounded-lg flex flex-col gap-y-4 mb-4">
      <div className="flex justify-between items-center px-4 pt-4">
        <div className="text-base font-medium">{PROMO_TITLE}</div>
        {fileDownloadEl}
      </div>
      {summaryEl}
      {listEl}
    </div>
  );
};

PromoAnalytics.propTypes = {
  promoSummary: PropTypes.object,
  promoAnalytics: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  promoSummary: makeSelectProperty('promoSummary'),
  promoAnalytics: makeSelectProperty('promoAnalytics'),
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(PromoAnalytics);
