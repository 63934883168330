import React from 'react';
import PropTypes from 'prop-types';
import { SCInput } from 'rollup-allspark';
import SCInputWithPrefixSuffix from '../../../../components/SCInputWithPrefixSuffix';
import { cn, isEmpty } from '../../../../utils';

const ModifierRow = ({
  index = null,
  currencySymbol = '',
  modifier = {},
  modifiers = [],
  handleModifierChange,
}) => {
  const { title = '', unit_price: unitPrice = 0, quantity = 0 } = modifier;

  const handleChange = e => {
    const { name, value } = e.target;

    const newModifiers = [...modifiers];
    newModifiers[index] = {
      ...newModifiers[index],
      [name]: value,
    };
    handleModifierChange(newModifiers);
  };

  const handleRemove = () => {
    const newModifiers = modifiers.filter((_, i) => i !== index);
    handleModifierChange(newModifiers);
  };

  return (
    <div className="flex items-center justify-between gap-x-4">
      <div className="flex items-center gap-x-4">
        <SCInput
          name="title"
          type="text"
          label="Modifier"
          className="w-1/2"
          placeholder="Modifier title"
          inputClass="border rounded-lg"
          hasError={isEmpty(title)}
          value={title}
          handleChange={handleChange}
        />
        <div className="sc-input-container w-1/5">
          <label htmlFor="unit_price">Unit Price</label>
          <SCInputWithPrefixSuffix
            prefix={currencySymbol}
            type="number"
            placeholder="Unit Price"
            value={unitPrice}
            name="unit_price"
            id="unit_price"
            hasError={unitPrice < 0}
            handleChange={handleChange}
            className={cn({
              'border-grey-dark rounded-lg mt-1': true,
              'text-red-dark border-red-dark': unitPrice < 0,
            })}
          />
        </div>
        <SCInput
          name="quantity"
          type="number"
          label="Count"
          className="w-1/5"
          placeholder="Quantity"
          inputClass="border rounded-lg"
          hasError={quantity < 1}
          value={quantity}
          handleChange={handleChange}
        />
      </div>
      <button
        type="button"
        onClick={handleRemove}
        className="flex items-center justify-end gap-x-1 p-1"
      >
        <i className="kt-x text-red-dark text-sm" />
        <p className="text-xs text-red-dark font-medium line-clamp-1">
          Remove Modifier
        </p>
      </button>
    </div>
  );
};

ModifierRow.propTypes = {
  index: PropTypes.number,
  currencySymbol: PropTypes.string,
  modifier: PropTypes.object,
  modifiers: PropTypes.array,
  handleModifierChange: PropTypes.func,
};

export default ModifierRow;
