import PropTypes from 'prop-types';
import React from 'react';
import notFound from '../assets/images/not-found-exclamation.svg';

const ComponentPlaceholder = ({
  imgSrc = notFound,
  imgHeight = 144,
  imgWidth = 144,
  label = 'Pheww! There’s nothing here, yet',
  subLabel = '',
  isLabelOnTop = false,
  children = null,
  containerClass = '',
  subLabelClass = '',
  labelClass = '',
}) => {
  const positionClass = `flex items-center justify-center ${
    isLabelOnTop ? 'flex-col-reverse' : 'flex-col'
  }`;
  const subLabelEl = subLabel ? (
    <p className={`text-black text-xs mt-2 ${subLabelClass}`}>{subLabel}</p>
  ) : null;

  return (
    <div
      className={`flex flex-col items-center justify-center ${containerClass}`}
    >
      <div className={positionClass}>
        <img src={imgSrc} alt={label} width={imgWidth} height={imgHeight} />
        <div className={`font-medium text-sm ${labelClass}`}>{label}</div>
        {subLabelEl}
      </div>
      {children}
    </div>
  );
};
ComponentPlaceholder.propTypes = {
  imgSrc: PropTypes.string,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  isLabelOnTop: PropTypes.bool,
  children: PropTypes.element,
  containerClass: PropTypes.string,
  labelClass: PropTypes.string,
  subLabelClass: PropTypes.string,
};

export default ComponentPlaceholder;
