import { LOCALE_SETTINGS, ROUNDED_CURRENCIES } from '../config/constants/geo';
import { isEmpty, isString } from './index';

export const abbreviateNumber = n => {
  if (n < 1e3) return Number.isInteger(n) ? n : n.toFixed(2);
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
  return null;
};

export const getCurrency = (currency = {}) =>
  !isEmpty(currency) && currency.symbol ? currency.symbol : '$';

export const getDisplayPrice = (
  price,
  thousandSeparator = ',',
  decimalSeparator = '.',
) => {
  const thousandSeparatedPrice = price
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

  if (decimalSeparator === '.') {
    return thousandSeparatedPrice;
  }
  return thousandSeparatedPrice.replace(/.([^.]*)$/, ',$1');
};

export const centToMoney = (cent, fractionDigits = 2) => {
  if (!cent) {
    return fractionDigits === 0 ? '0' : '0.00';
  }
  return (cent / 100).toFixed(fractionDigits);
};

export const readablePrice = (cent = 0, currency = {}) => {
  const locale = LOCALE_SETTINGS[currency?.code];
  const symbol = getCurrency(currency);
  if (!locale) return `${symbol}${cent / 100}`;

  let displayPrice = '';

  if (ROUNDED_CURRENCIES.includes(currency?.code)) {
    const money = Math.round(cent / 100);

    const currencyFractionDigits = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency?.code,
    }).resolvedOptions().maximumFractionDigits;

    displayPrice = money.toLocaleString(locale, {
      maximumFractionDigits: currencyFractionDigits,
    });
  } else {
    const money = centToMoney(cent, 2);
    displayPrice = getDisplayPrice(money, ',', '.');
  }

  return `${symbol}${displayPrice}`;
};

export const getReadableRoundOff = (amount = 0, currency) => {
  // Determine the positive or negative sign based on totalCents
  const sign = amount >= 0 ? '+' : '-';

  // Format the currency display value with the sign
  return `${sign}${readablePrice(Math.abs(amount), currency)}`;
};

export const toCent = money => {
  if (!money || Number.isNaN(money)) {
    return 0;
  }
  let value = money;
  if (isString(money)) {
    value = Number(money);
  }
  return Math.round(value * 100 * 1e12) / 1e12;
};
