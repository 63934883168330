import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCSelect } from 'rollup-allspark';
import ComponentPlaceholder from '../../../components/ComponentPlaceholder';
import { CDN_URL } from '../../../config';
import { KLIKIT_PROVIDER_ID } from '../../../config/constants';
import { isEmpty } from '../../../utils';
import { makeSelectGlobalProperty } from '../../App/selectors';
import { updateEntity } from '../actions';
import { makeSelectProperty } from '../selector';
import Integrated from './Integrated';
import Interceptor from './Interceptor';

const Cart = ({
  providerBrands = [],
  providers = [],
  selectedBranch = null,
  selectedBrand = null,
  selectedProvider = null,
  updateState = () => {},
}) => {
  const [aggregators, setAggregators] = useState([]);
  useEffect(() => {
    const filtered = providers.filter(pb => pb.id !== KLIKIT_PROVIDER_ID) || [];
    setAggregators(filtered);
  }, [providers]);
  const { id: providerId = null, title: providerTitle = '' } =
    selectedProvider || {};

  const handleProviderChange = value => {
    updateState({
      cartItems: [],
      selectedProvider: value,
    });
  };

  const labelEl = provider => {
    const { logo, title } = provider;

    return (
      <div className="flex items-center gap-x-1">
        <img
          src={`${CDN_URL}${logo}`}
          alt={title}
          className="w-6 h-6 rounded-full"
        />
        <span className="text-sm font-medium">{title}</span>
      </div>
    );
  };

  const { is_interceptor: isInterceptor = null } =
    providerBrands.find(
      ({ provider_id: pId, branch_id: bhId, brand_id: bdId }) =>
        pId === providerId &&
        bhId === selectedBranch?.id &&
        bdId === selectedBrand?.id,
    ) || {};

  const cartEl = (() => {
    if (isEmpty(isInterceptor) || isEmpty(selectedProvider)) {
      return (
        <ComponentPlaceholder
          label={
            isEmpty(selectedProvider)
              ? 'Please select a provider to proceed'
              : `${providerTitle} settings is not available for the selected branch and brand`
          }
        />
      );
    }

    return isInterceptor ? <Interceptor /> : <Integrated />;
  })();

  return (
    <div className="bg-white p-4 rounded border border-grey">
      <p className="text-base font-semibold">Cart</p>
      <SCSelect
        searchable
        title="Aggregator"
        className="w-2/5 mt-4"
        options={aggregators}
        value={selectedProvider}
        customLabel={labelEl}
        trackBy="id"
        searchAbleAttribute={['title', 'id']}
        placeholder="Select Aggregator"
        onSelect={handleProviderChange}
      />
      {cartEl}
    </div>
  );
};

Cart.propTypes = {
  providerBrands: PropTypes.array,
  providers: PropTypes.array,
  selectedBranch: PropTypes.object,
  selectedBrand: PropTypes.object,
  selectedProvider: PropTypes.object,
  updateState: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  providerBrands: makeSelectProperty('providerBrands'),
  providers: makeSelectGlobalProperty('providerList'),
  selectedBranch: makeSelectProperty('selectedBranch'),
  selectedBrand: makeSelectProperty('selectedBrand'),
  selectedProvider: makeSelectProperty('selectedProvider'),
});

const mapDispatchToProps = dispatch => ({
  updateState: meta => dispatch(updateEntity(meta)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Cart);
