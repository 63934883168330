import { create } from 'mutative';
import {
  ORDER_STATUS,
  ORDER_STATUS_LIST,
  ORDER_TYPE_LIST,
} from '../../config/constants/orders';
import { isFunction } from '../../utils';
import { LOGOUT_DONE } from '../Auth/constants';
import {
  ACTION_RESOLVED,
  GET_ENTITY,
  GET_LIST,
  SAVE_ENTITY,
  UPDATE_ENTITY,
} from './constants';

export const initialState = {
  branches: [],
  brands: [],
  cartItems: [],
  deliveryFee: 0,
  discountAmount: 0,
  externalId: '',
  isLoading: false,
  items: [],
  modifiers: [],
  page: 0,
  paymentChannels: [],
  paymentStatus: null,
  paymentStatusList: [],
  providerBrands: [],
  providers: [],
  selectedBranch: null,
  selectedBrand: null,
  selectedBusiness: null,
  selectedDate: null,
  selectedPaymentChannel: null,
  selectedProvider: null,
  selectedTime: 1000,
  shortId: '',
  size: 0,
  status: ORDER_STATUS_LIST.find(s => s.id === ORDER_STATUS.DELIVERED),
  total: 0,
  type: ORDER_TYPE_LIST[0],
  vatAmount: 0,
};

const reducer = (state = initialState, action) =>
  create(state, draft => {
    const actionParamKeys = Object.keys(action.meta || {});

    switch (action.type) {
      case UPDATE_ENTITY: {
        actionParamKeys.forEach(k => {
          const valOrCb = action.meta[k];
          if (isFunction(valOrCb)) {
            draft[k] = valOrCb(draft[k]);
          } else {
            draft[k] = valOrCb;
          }
        });
        break;
      }

      case GET_ENTITY:
      case GET_LIST:
      case SAVE_ENTITY:
        draft[action.meta?.loaderKey || 'isLoading'] = true;
        break;
      case ACTION_RESOLVED:
        draft[action.meta?.loaderKey || 'isLoading'] = false;
        break;

      case LOGOUT_DONE:
        initialState.forEach(key => {
          draft[key] = initialState[key];
        });
        break;

      default:
        break;
    }
  });
export default reducer;
