import PropTypes from 'prop-types';
import React, { useDeferredValue, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCLoader } from 'rollup-allspark';
import HelpPopover from '../../../components/HelpPopover';
import { ITEM_TABLE_BREAKDOWN_HELP } from '../../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import {
  COMPARISON_TYPE,
  EXPORT_TYPE,
  ITEM_BREAKDOWN_TABS,
} from '../../../config/constants/analytics';
import { cn, printf } from '../../../utils';
import { getCSV, getXLSX, loadItemBreakdownData } from '../actions';
import { EXPORT_ITEM_LEVEL_BREAKDOWN } from '../sagaMap';
import { makeSelectProperty } from '../selectors';
import ItemBreakDownTable from './ItemBreakDownTable';
import ItemBreakDownTableWithProvider from './ItemBreakDownTableWithProvider';

const ItemBreakDown = ({
  handleItemVisibilityTab,
  filterData,
  getItemBreakdownData,
  itemBreakDownData,
  itemBreakDownDataLoading,
  selectedComparisonType,
  analyticsProviders,
  downloadCSV,
  isDownloadingCSV,
  downloadXLSX,
}) => {
  // HOOKS
  const [isItemBreakdownPopoverOpen, setIsItemBreakdownPopoverOpen] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState(ITEM_BREAKDOWN_TABS[0]);
  const [tableData, setTableData] = useState([]);
  const comparisonType = useDeferredValue(selectedComparisonType);

  useEffect(() => {
    setTableData(itemBreakDownData || null);
  }, [itemBreakDownData]);

  // HANDLERS
  const getFilteredItemBreakdownData = id => {
    const params = { ...filterData };
    params.activeTab = id;
    getItemBreakdownData(params);
  };

  const handleChangeTab = tab => {
    handleItemVisibilityTab(tab.id);
    setSelectedTab(tab);
    getFilteredItemBreakdownData(tab.id);
  };

  const handleDownloadClicked = exportType => {
    const url = printf(EXPORT_ITEM_LEVEL_BREAKDOWN.url, selectedTab.key);
    const fileName = `${selectedTab.title} Breakdown`;
    const params = { ...filterData, export_option: exportType };

    if (exportType === EXPORT_TYPE.CSV) {
      downloadCSV({
        ...EXPORT_ITEM_LEVEL_BREAKDOWN,
        url,
        params,
        fileName,
      });
    } else if (exportType === EXPORT_TYPE.XLSX) {
      downloadXLSX({
        ...EXPORT_ITEM_LEVEL_BREAKDOWN,
        url,
        params,
        fileName,
      });
    }
  };

  // RENDERS
  // Tabs
  const tabsEl = (() => {
    const tabs = ITEM_BREAKDOWN_TABS.map(ct => (
      <div
        key={ct.id}
        role="presentation"
        className={cn({
          'py-3 rounded font-medium text-grey-darker w-full text-center cursor-pointer': true,
          'bg-white text-black': selectedTab.id === ct.id,
        })}
        onClick={() => handleChangeTab(ct)}
      >
        {ct.title}
      </div>
    ));

    return (
      <div className="flex items-center justify-center p-1 bg-grey-light rounded my-4">
        {tabs}
      </div>
    );
  })();

  // loading placeholder
  const renderLoadingPlaceholder = (
    <div className="w-full h-20">
      <SCLoader lineWidth={5} diameter={50} />
    </div>
  );

  // AggregatedTable
  const aggregatedTable = itemBreakDownDataLoading ? (
    renderLoadingPlaceholder
  ) : (
    <ItemBreakDownTable
      tableData={tableData}
      currentTab={selectedTab}
      itemBreakDownDataLoading={itemBreakDownDataLoading}
      onDownloadClicked={handleDownloadClicked}
      isDownloadingCSV={isDownloadingCSV}
    />
  );

  // providerWiseTable
  const providerWiseTable = itemBreakDownDataLoading ? (
    renderLoadingPlaceholder
  ) : (
    <ItemBreakDownTableWithProvider
      tableData={tableData}
      currentTab={selectedTab}
      analyticsProviders={analyticsProviders}
      itemBreakDownDataLoading={itemBreakDownDataLoading}
      onDownloadClicked={handleDownloadClicked}
      isDownloadingCSV={isDownloadingCSV}
    />
  );

  const tableEl =
    comparisonType === COMPARISON_TYPE.AGGREGATOR
      ? providerWiseTable
      : aggregatedTable;

  return (
    <div className="flex flex-col p-4 w-full justify-between relative">
      <div className="flex flex-col text-black mb-2">
        <div className="text-lg font-medium capitalize flex leading-8">
          Menu Report
          <HelpPopover
            isPopoverOpen={isItemBreakdownPopoverOpen}
            position="right"
            isHtmlElement
            content={ITEM_TABLE_BREAKDOWN_HELP}
            setIsPopoverOpen={setIsItemBreakdownPopoverOpen}
            iconClassNames="sc-information cursor-pointer ml-2 flex items-center h-full"
          />
        </div>
      </div>
      {tabsEl}
      {tableEl}
    </div>
  );
};

ItemBreakDown.propTypes = {
  handleItemVisibilityTab: PropTypes.func,
  filterData: PropTypes.object,
  getItemBreakdownData: PropTypes.func,
  itemBreakDownData: PropTypes.any,
  itemBreakDownDataLoading: PropTypes.bool,
  selectedComparisonType: PropTypes.string,
  analyticsProviders: PropTypes.array,
  downloadCSV: PropTypes.func,
  isDownloadingCSV: PropTypes.bool,
  downloadXLSX: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  itemBreakDownData: makeSelectProperty('itemBreakDownData'),
  itemBreakDownDataLoading: makeSelectProperty('itemBreakDownDataLoading'),
  selectedComparisonType: makeSelectProperty('selectedComparisonType'),
  analyticsProviders: makeSelectProperty('analyticsProviders'),
  isDownloadingCSV: makeSelectProperty('isDownloadingCSV'),
});

const mapDispatchToProps = dispatch => ({
  getItemBreakdownData: params => dispatch(loadItemBreakdownData(params)),
  downloadCSV: params => dispatch(getCSV(params)),
  downloadXLSX: params => dispatch(getXLSX(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ItemBreakDown);
