import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCButton, SCInput, SCModal, SCModalBody } from 'rollup-allspark';
import { isFunction, isNumber } from '../../../utils';
import { updateEntity } from '../actions';
import { makeSelectProperty } from '../selector';

const Discount = ({
  discountAmount,
  isOpen = false,
  onClose = () => {},
  updateField = () => {},
}) => {
  const ref = useRef(null);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    if (isOpen) {
      ref.current.showModal();
    } else {
      ref.current.closeModal();
    }
  }, [isOpen]);

  useEffect(() => {
    setDiscount(discountAmount);
  }, [discountAmount]);

  const onModalClose = () => {
    if (isFunction(onClose)) {
      onClose();
    }
  };

  const onConfirm = () => {
    updateField({
      discountAmount: discount,
    });
    onModalClose();
  };

  const body = (
    <SCModalBody>
      <div className="flex flex-col gap-y-4 normal-case">
        <div className="text-base font-medium text-black">Discount Amount</div>
        <SCInput
          type="number"
          inputClass="border rounded-lg scam-business-name"
          value={discount}
          handleChange={e => setDiscount(parseFloat(e.target.value) || 0)}
          hasError={!isNumber(discount)}
          hint={
            !isNumber(discount) ? 'Please enter a valid Discount Amount' : ''
          }
        />
        <div className="pt-8 flex gap-x-4 justify-end">
          <SCButton variant="danger-outline" action={onModalClose}>
            Cancel
          </SCButton>
          <SCButton action={onConfirm}>Confirm</SCButton>
        </div>
      </div>
    </SCModalBody>
  );

  return (
    <SCModal
      ref={ref}
      variant="white"
      body={body}
      size="lg"
      onModalClose={onModalClose}
    />
  );
};

Discount.propTypes = {
  discountAmount: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  updateField: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  discountAmount: makeSelectProperty('discountAmount'),
});

const mapDispatchToProps = dispatch => ({
  updateField: val => dispatch(updateEntity(val)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Discount);
