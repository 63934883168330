import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCSelect } from 'rollup-allspark';
import {
  DEFAULT_ALL_PAGE_SIZE,
  DEFAULT_PAGE_NO,
  ORDER_STATUS,
  PAYMENT_STATUS_PAID_ID,
} from '../../config';
import {
  ORDER_STATUS_LIST,
  ORDER_TYPE_LIST,
} from '../../config/constants/orders';
import { isEmpty, printf } from '../../utils';
import { makeSelectGlobalProperty } from '../App/selectors';
import { getList, updateEntity } from './actions';
import { BRANCH_LIST, BRAND_LIST, PROVIDER_BRAND_LIST } from './sagaMap';
import { makeSelectProperty } from './selector';

const Filters = ({
  branches,
  brands,
  businesses,
  getBranches,
  getBrands,
  getProviderBrands,
  paymentStatusList,
  selectedBranch,
  selectedBrand,
  selectedBusiness,
  updateField,
}) => {
  const handleBusinessChange = biz => {
    if (!isEmpty(biz)) {
      getBranches({
        ...BRANCH_LIST,
        isPaginated: false,
        params: {
          page: DEFAULT_PAGE_NO,
          size: DEFAULT_ALL_PAGE_SIZE,
          filterByBusiness: biz.id,
        },
      });

      getBrands({
        ...BRAND_LIST,
        params: {
          page: DEFAULT_PAGE_NO,
          size: DEFAULT_ALL_PAGE_SIZE,
          filterByBusiness: biz.id,
        },
      });
    }

    updateField({
      branches: [],
      brands: [],
      cartItems: [],
      deliveryFee: 0,
      discountAmount: 0,
      externalId: '',
      paymentStatus: isEmpty(paymentStatusList)
        ? null
        : paymentStatusList.find(ps => ps.id === PAYMENT_STATUS_PAID_ID),
      selectedBusiness: biz,
      selectedBrand: null,
      selectedBranch: null,
      selectedDate: null,
      selectedProvider: null,
      status: ORDER_STATUS_LIST.find(s => s.id === ORDER_STATUS.DELIVERED),
      type: ORDER_TYPE_LIST[0],
      vatAmount: 0,
    });
  };

  const handleBrandChange = brand => {
    updateField({ selectedBrand: brand });
  };

  const handleBranchChange = branch => {
    updateField({ selectedBranch: branch });
    getProviderBrands({
      ...PROVIDER_BRAND_LIST,
      url: printf(PROVIDER_BRAND_LIST.url, branch.id),
    });
  };

  return (
    <div className="bg-white p-4 rounded flex gap-x-4 border border-grey">
      <SCSelect
        searchable
        title="Business"
        className="w-1/3"
        options={businesses}
        value={selectedBusiness}
        customLabel={b => (isEmpty(b) ? '' : `${b.id}. ${b.title}`)}
        searchAbleAttribute={['title', 'id']}
        trackBy="id"
        placeholder="Select Business"
        disabled={isEmpty(businesses)}
        onSelect={handleBusinessChange}
      />
      <SCSelect
        searchable
        title="Branch"
        className="w-1/3"
        options={branches}
        value={selectedBranch}
        customLabel={b => (isEmpty(b) ? '' : `${b.id}. ${b.title}`)}
        searchAbleAttribute={['title', 'id']}
        trackBy="id"
        placeholder="Select Branch"
        disabled={isEmpty(businesses)}
        onSelect={handleBranchChange}
      />
      <SCSelect
        searchable
        title="Brand"
        className="w-1/3"
        options={brands}
        value={selectedBrand}
        customLabel={b => (isEmpty(b) ? '' : `${b.id}. ${b.title}`)}
        searchAbleAttribute={['title', 'id']}
        trackBy="id"
        placeholder="Select Brand"
        disabled={isEmpty(businesses)}
        onSelect={handleBrandChange}
      />
    </div>
  );
};

Filters.propTypes = {
  branches: PropTypes.array,
  brands: PropTypes.array,
  businesses: PropTypes.array,
  getBranches: PropTypes.func,
  getBrands: PropTypes.func,
  getProviderBrands: PropTypes.func,
  paymentStatusList: PropTypes.array,
  selectedBranch: PropTypes.object,
  selectedBrand: PropTypes.object,
  selectedBusiness: PropTypes.object,
  updateField: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  branches: makeSelectProperty('branches'),
  brands: makeSelectProperty('brands'),
  businesses: makeSelectGlobalProperty('businessList'),
  paymentStatusList: makeSelectProperty('paymentStatusList'),
  selectedBranch: makeSelectProperty('selectedBranch'),
  selectedBrand: makeSelectProperty('selectedBrand'),
  selectedBusiness: makeSelectProperty('selectedBusiness'),
});

const mapDispatchToProps = dispatch => ({
  getBranches: meta => dispatch(getList(meta)),
  getBrands: meta => dispatch(getList(meta)),
  getProviderBrands: meta => dispatch(getList(meta)),
  updateField: meta => dispatch(updateEntity(meta)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Filters);
