import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeSelectProperty } from '../../selector';
import { updateEntity } from '../../actions';
import { cn, isEmpty } from '../../../../utils';
import ItemRow from './ItemRow';

const Interceptor = ({ cartItems = [], updateState }) => {
  const isAddDisabled = cartItems.some(
    item =>
      isEmpty(item.title) ||
      item.unit_price < 1 ||
      item.quantity < 1 ||
      item.modifiers.some(
        mod => isEmpty(mod.title) || mod.unit_price < 0 || mod.quantity < 1,
      ),
  );

  const handleItemAdd = () => {
    if (isAddDisabled) return;
    const id = cartItems.length + 1;
    const newItem = {
      id,
      title: '',
      unit_price: 0,
      total_price: 0,
      quantity: 0,
      modifiers: [],
    };

    updateState({
      cartItems: [...cartItems, newItem],
    });
  };

  const itemsEl = (() => {
    if (isEmpty(cartItems)) return null;

    const rowsEl = cartItems.map((item, index) => (
      <ItemRow
        key={item.id}
        index={index}
        item={item}
        isDisabled={isAddDisabled}
      />
    ));

    return <div className="flex flex-col gap-y-4">{rowsEl}</div>;
  })();

  return (
    <div className="w-full mt-4">
      {itemsEl}
      <button
        type="button"
        onClick={handleItemAdd}
        disabled={isAddDisabled}
        className={cn({
          'flex items-center justify-center w-2/5 rounded-lg bg-grey gap-x-1 h-10 mt-4': true,
          'opacity-50': isAddDisabled,
        })}
      >
        <i className="kt-plus text-lg" />
        <p className="text-xs font-medium">Add Item</p>
      </button>
    </div>
  );
};

Interceptor.propTypes = {
  cartItems: PropTypes.array,
  updateState: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  cartItems: makeSelectProperty('cartItems'),
});

const mapDispatchToProps = dispatch => ({
  updateState: meta => dispatch(updateEntity(meta)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Interceptor);
