import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { SCButton, SCPagination } from 'rollup-allspark';
import HelpPopover from '../../../components/HelpPopover';
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZES,
} from '../../../config';
import { EXPORT_TYPE } from '../../../config/constants/analytics';
import { cn, isEmpty } from '../../../utils';
import { readablePrice } from '../../../utils/getCurrency';
import {
  generateProviderSpecificTableData,
  sortProviderSpecificDataRow,
} from '../../../utils/nativeAnalyticsUtils';

const ItemBreakDownTableWithProvider = ({
  tableData,
  currentTab,
  analyticsProviders,
  itemBreakDownDataLoading,
  onDownloadClicked,
  isDownloadingCSV,
}) => {
  // HOOKS
  const [isNameColumnHeaderIsOpen, setIsNameColumnHeaderIsOpen] =
    useState(false);
  const [isQuantityColumnHeaderIsOpen, setIsQuantityColumnHeaderIsOpen] =
    useState(false);
  const [isRevenueColumnHeaderIsOpen, setIsRevenueColumnHeaderIsOpen] =
    useState(false);
  const [tableFlattenedData, setTableFlattenedData] = useState({});
  const [sortOrder, setSortOrder] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NO);
  const [currentSize, setCurrentSize] = useState(DEFAULT_PAGE_SIZE);
  const { currency, currency_symbol } = tableData || {};
  const currencyObj = { code: currency, symbol: currency_symbol };
  useEffect(() => {
    const indexOfLastPerm = currentPage * currentSize;
    const indexOfFirstPerm = indexOfLastPerm - currentSize;
    let paginatedData = [];
    let sortedData = [];

    const flattenedData = generateProviderSpecificTableData(
      tableData && tableData.analytics && tableData.analytics.length
        ? tableData.analytics
        : [],
    );

    if (
      flattenedData &&
      flattenedData.providerDataRow &&
      flattenedData.providerDataRow.length
    ) {
      sortedData = sortProviderSpecificDataRow(
        flattenedData,
        sortBy,
        sortOrder,
      );
      paginatedData = sortedData.providerDataRow.slice(
        indexOfFirstPerm,
        indexOfLastPerm,
      );
    }

    const paginatedFlattenedData = {
      ...flattenedData,
      providerDataRow: paginatedData,
    };

    setTableFlattenedData(paginatedFlattenedData);
  }, [tableData, sortOrder, sortBy, currentPage, currentSize]);

  const handleSorting = newSortBy => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(newSortBy);
    setCurrentPage(DEFAULT_PAGE_NO);
  };
  const getSortClass = field => {
    let sortClass = 'kt-arrow-sort text-base';
    if (sortOrder === 'asc' && sortBy === field) {
      sortClass = 'kt-arrow-up';
    } else if (sortOrder === 'desc' && sortBy === field) {
      sortClass = 'kt-arrow-down';
    }
    return cn(sortClass, 'cursor-pointer');
  };
  const getRevenueKey = () => {
    if (tableData && tableData.currency === 'USD') {
      return 'revenueUsd';
    }
    return 'revenue';
  };
  const getProviderRevenueKey = () => {
    if (tableData && tableData.currency === 'USD') {
      return 'providerRevenuesInUsd';
    }
    return 'providerRevenues';
  };
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setCurrentSize(size);
  };
  // RENDER
  const pagination = !itemBreakDownDataLoading ? (
    <SCPagination
      className="mt-4"
      total={tableData && tableData.analytics ? tableData.analytics.length : 0}
      page={currentPage}
      size={currentSize}
      sizes={DEFAULT_PAGE_SIZES}
      onPageChange={handlePageChange}
    />
  ) : null;
  const renderNoDataRow = (
    <tr className="text-center">
      <td colSpan="11" className="p-5 text-2xl text-grey-darker h-20">
        No data Found
      </td>
    </tr>
  );
  // TABLE HEADER
  const tableHeaderBaseClass = 'bg-grey-lighter border-b capitalize p-3';
  const tableCellBaseClasses = 'p-4 border-b';

  const providerSpecificCells = (rowData, key) => {
    if (!rowData || !rowData[key]) {
      return null;
    }
    const cells = rowData[key];
    return cells.map((cell, index) => {
      if (
        key === 'providerQuantitiesTitle' ||
        key === 'providerRevenuesTitle'
      ) {
        const provider = analyticsProviders.find(p => p.id === cell);
        const showTitle = rowData[key].includes(cell);
        return (
          <th
            key={`${key}-${cell}-${index + 1}`}
            className={cn(
              tableCellBaseClasses,
              'border-r text-grey-darker text-right bg-grey-lighter',
            )}
          >
            {showTitle && provider && provider.title ? provider.title : ''}
          </th>
        );
      }
      return (
        <td
          key={`${key}-${cell}-${index + 1}`}
          className={cn(tableCellBaseClasses, 'border-r text-right')}
        >
          {key === 'providerQuantities'
            ? cell
            : readablePrice(cell, currencyObj)}
        </td>
      );
    });
  };

  const tableHeaders = ['', 'Quantity Ordered', 'Revenue'];
  const renderTableHeader = (
    <thead>
      <tr>
        <th className={cn(tableHeaderBaseClass, 'border-r')} rowSpan={2}>
          <div className="flex justify-between">
            <div className="flex">
              {currentTab && currentTab.title ? currentTab.title : ''} Name
              <HelpPopover
                isPopoverOpen={isNameColumnHeaderIsOpen}
                position="right"
                isHtmlElement
                content={`Menu  ${currentTab.title}`}
                setIsPopoverOpen={setIsNameColumnHeaderIsOpen}
                iconClassNames="sc-information cursor-pointer ml-1"
              />
            </div>
            <button
              type="button"
              className={getSortClass('title')}
              onClick={() => handleSorting('title')}
            >
              {/* button content */}
            </button>
          </div>
        </th>
        <th
          className={cn(tableHeaderBaseClass, 'border-r')}
          colSpan={
            tableFlattenedData && Object.keys(tableFlattenedData).length
              ? tableFlattenedData.uniqueProvidersLength
              : 0
          }
        >
          <div className="flex justify-between">
            <div className="flex">
              {tableHeaders[1]}
              <HelpPopover
                isPopoverOpen={isQuantityColumnHeaderIsOpen}
                position="right"
                isHtmlElement
                content={`${currentTab.title} Quantity is the total number of times the menu ${currentTab.title} has been ordered from`}
                setIsPopoverOpen={setIsQuantityColumnHeaderIsOpen}
                iconClassNames="sc-information cursor-pointer ml-1"
              />
            </div>
            <button
              type="button"
              className={getSortClass('quantity')}
              onClick={() => handleSorting('quantity')}
            >
              {/* button content */}
            </button>
          </div>
        </th>
        <th
          className={tableHeaderBaseClass}
          colSpan={
            tableFlattenedData && Object.keys(tableFlattenedData).length
              ? tableFlattenedData.uniqueProvidersLength
              : 0
          }
        >
          <div className="flex justify-between">
            <div className="flex">
              {tableHeaders[2]}
              <HelpPopover
                isPopoverOpen={isRevenueColumnHeaderIsOpen}
                position="right"
                isHtmlElement
                content={`The total gross sales for the ${currentTab.title}`}
                setIsPopoverOpen={setIsRevenueColumnHeaderIsOpen}
                iconClassNames="sc-information cursor-pointer ml-1"
              />
            </div>
            <button
              type="button"
              className={getSortClass(getRevenueKey())}
              onClick={() => handleSorting(getRevenueKey())}
            >
              {/* button content */}
            </button>
          </div>
        </th>
      </tr>
      <tr>
        {providerSpecificCells(
          tableFlattenedData.providerTitleRow,
          'providerQuantitiesTitle',
        )}
        {providerSpecificCells(
          tableFlattenedData.providerTitleRow,
          'providerRevenuesTitle',
        )}
      </tr>
    </thead>
  );

  // TABLE BODY
  const renderTableRows = tableRowData => {
    if (!tableRowData) {
      return null;
    }

    const providerDataRow = tableRowData.providerDataRow.map(row => (
      <tr key={row.title}>
        <td className={`${tableCellBaseClasses} border-r`}>{row.title}</td>
        {providerSpecificCells(row, 'providerQuantities')}
        {providerSpecificCells(row, getProviderRevenueKey())}
      </tr>
    ));
    return providerDataRow;
  };

  const renderDownloadCSVButton =
    tableData && tableData.analytics && tableData.analytics.length ? (
      <div className="absolute top-0 right-0">
        <div className="flex items-center gap-x-2 mr-4 mt-5">
          <SCButton
            variant="secondary"
            className="h-6 rounded-lg bg-grey-light text-black hover:bg-grey gap-x-1 w-auto flex items-center px-4 text-xs font-medium"
            action={() => onDownloadClicked(EXPORT_TYPE.CSV)}
            disabled={isDownloadingCSV}
          >
            <i className="kt-download text-lg" />
            <span>CSV</span>
          </SCButton>
          <SCButton
            variant="secondary"
            className="h-6 rounded-lg bg-grey-light text-black hover:bg-grey gap-x-1 w-auto flex items-center px-4 text-xs font-medium"
            action={() => onDownloadClicked(EXPORT_TYPE.XLSX)}
            disabled={isDownloadingCSV}
          >
            <i className="kt-download text-lg" />
            <span>XLSX</span>
          </SCButton>
        </div>
      </div>
    ) : null;

  return (
    <div className="bg-white w-full">
      {renderDownloadCSVButton}
      <div className="overflow-x-auto mb-8">
        <table className="table-auto w-full" id="item-breakdown-provider-table">
          {renderTableHeader}
          <tbody>
            {!isEmpty(tableFlattenedData.providerDataRow)
              ? renderTableRows(tableFlattenedData)
              : null}
            {isEmpty(tableFlattenedData?.providerDataRow)
              ? renderNoDataRow
              : null}
          </tbody>
        </table>
      </div>
      {!isEmpty(tableFlattenedData?.providerDataRow) ? pagination : null}
    </div>
  );
};

ItemBreakDownTableWithProvider.propTypes = {
  tableData: PropTypes.any,
  currentTab: PropTypes.any,
  analyticsProviders: PropTypes.array,
  itemBreakDownDataLoading: PropTypes.bool,
  onDownloadClicked: PropTypes.func,
  isDownloadingCSV: PropTypes.bool,
};

export default ItemBreakDownTableWithProvider;
