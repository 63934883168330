import { KLIKIT_CDN_BASE_URL } from '../env';

export const NEW_NOTIFICATION_SOUND = `${KLIKIT_CDN_BASE_URL}/audio/new.wav`;
export const CANCELLED_ORDER_NOTIFICATION_SOUND = `${KLIKIT_CDN_BASE_URL}/audio/cancel.wav`;

export const ORDER_STATUS = {
  PLACED: 1,
  ACCEPTED: 2,
  CANCELLED: 3,
  READY: 4,
  DELIVERED: 5,
  SCHEDULED: 6,
  DRIVER_ASSIGNED: 7,
  DRIVER_ARRIVED: 8,
  PICKEDUP: 9,
  VOIDED: 11,
};

export const ORDER_STATUS_LIST = [
  { id: 1, status: 'Placed' },
  { id: 2, status: 'Accepted' },
  { id: 3, status: 'Cancelled' },
  { id: 4, status: 'Ready' },
  { id: 5, status: 'Delivered' },
  { id: 6, status: 'Scheduled' },
  { id: 7, status: 'Driver Assigned' },
  { id: 8, status: 'Driver Arrived' },
  { id: 9, status: 'Picked Up' },
  { id: 11, status: 'Voided' },
];

export const ORDER_TYPE = {
  DELIVER: 2,
  PICKUP: 1,
  DINE_IN: 3,
};
export const ORDER_TYPE_LIST = [
  { id: 1, label: 'Pickup' },
  { id: 2, label: 'Delivery' },
  {
    id: 3,
    label: 'Dine In',
  },
];

export const PAID_PAYMENT_STATUS_ID = 1;
export const PENDING_PAYMENT_STATUS_ID = 3;
export const FAILED_PAYMENT_STATUS_ID = 2;
export const DEFAULT_PAYMENT_STATUS_ID_FOR_MANUAL_ORDER =
  PENDING_PAYMENT_STATUS_ID;
export const DEFAULT_PAYMENT_STATUS_LABEL_FOR_MANUAL_ORDER = 'Pending';
export const DISCOUNT_TYPE = [
  {
    id: 1,
    title: 'Flat',
    value: 1,
  },
  {
    id: 2,
    title: 'Percent (%)',
    value: 2,
  },
];

export const ACTIVE_ORDER_MODE = {
  title: 'Active Orders',
  id: 'active_orders',
};

export const PAST_ORDER_MODE = {
  title: 'Order History',
  id: 'past_orders',
};

export const ORDER_MODES = [ACTIVE_ORDER_MODE, PAST_ORDER_MODE];

export const OTHER_ORDER_SOURCE_ID = 10;
export const CUSTOMIZABLE_PRICE_TYPE = {
  DELIVERY_FEE: 'delivery_fee',
  DISCOUNT: 'discount_value',
  ADDITIONAL_FEE: 'additional_fee',
  IS_ROUNDING_OFF_APPLICABLE: 'is_rounding_off_applicable',
};

export const PAYMENT_STATUS_PAID_ID = 1;
export const PAYMENT_STATUS_REFUNDED_ID = 4;
export const PAYMENT_STATUS_REFUND_REQUESTED_ID = 5;
export const PAYMENT_STATUS_REFUND_REQUESTED_FAILED_ID = 6;
export const PAYMENT_STATUS_REFUND_FAILED = 7;

export const ORDER_FULFILLMENT_STATUS = {
  DISPATCHING: { id: 1, status: 'DISPATCHING', label: 'FINDING RIDER' },
  ALLOCATING: { id: 2, status: 'ALLOCATING', label: 'ALLOCATING RIDER' },
  PENDING_PICKUP: { id: 3, status: 'PENDING_PICKUP', label: 'FOUND RIDER' },
  PICKING_UP: { id: 4, status: 'PICKING_UP', label: 'PICKING UP' },
  IN_DELIVERY: { id: 5, status: 'IN_DELIVERY', label: 'IN DELIVERY' },
  COMPLETED: { id: 6, status: 'COMPLETED', label: 'COMPLETED' },
  RETURNED: { id: 7, status: 'RETURNED', label: 'RETURNED' },
  FAILED: { id: 8, status: 'FAILED', label: 'FAILED' },
  CANCELLED: { id: 9, status: 'CANCELLED', label: 'CANCELLED' },
  IN_RETURN: { id: 10, status: 'IN_RETURN', label: 'IN RETURN' },
  DISPATCH_FAILED: {
    id: 11,
    status: 'DISPATCH_FAILED',
    label: 'RIDER REQUEST FAILED',
  },
};

export const ORDER_FULFILLMENT_STATUS_IDS_HAVING_RIDER = [
  ORDER_FULFILLMENT_STATUS.PENDING_PICKUP.id,
  ORDER_FULFILLMENT_STATUS.PICKING_UP.id,
  ORDER_FULFILLMENT_STATUS.IN_DELIVERY.id,
  ORDER_FULFILLMENT_STATUS.COMPLETED.id,
  ORDER_FULFILLMENT_STATUS.IN_RETURN.id,
];

export const ORDER_CANCELLATION_LIST_TYPE_IDS = {
  RESTAURANT: 1,
  WEBSHOP: 2,
};
export const CANCELLATION_REASON_OTHER_ID = 7;

export const ORDER_CANCELLED_BY_LIST = [
  {
    id: 1,
    label: 'Restaurant',
  },
  {
    id: 2,
    label: 'Customer',
  },
  {
    id: 3,
    label: 'Provider',
  },
  {
    id: 4,
    label: 'Fulfillment Partner',
  },
  {
    id: 5,
    label: 'SYSTEM',
  },
];

export const PROVIDER_STATUS_ACTIVE = 'Active';
export const PROVIDER_STATUS_INACTIVE = 'Inactive';
export const PROVIDER_STATUS_NOT_AVAILABLE = 'N/A';

export const DRIVE_THRU_TYPE = {
  id: 2,
  label: 'Drive Thru',
};

export const CREATE_QRIS_ID = 37;

export const ACTIVE_ORDER_TABS = [
  {
    id: 1,
    title: 'All',
    orderStatus: [
      ORDER_STATUS.PLACED,
      ORDER_STATUS.ACCEPTED,
      ORDER_STATUS.READY,
      ORDER_STATUS.SCHEDULED,
      ORDER_STATUS.DRIVER_ASSIGNED,
      ORDER_STATUS.DRIVER_ARRIVED,
      ORDER_STATUS.PICKEDUP,
    ],
  },
  {
    id: 2,
    title: 'New',
    orderStatus: [ORDER_STATUS.PLACED, ORDER_STATUS.ACCEPTED],
  },
  {
    id: 3,
    title: 'Ready',
    orderStatus: [ORDER_STATUS.DRIVER_ARRIVED, ORDER_STATUS.READY],
  },
  {
    id: 4,
    title: 'Scheduled',
    orderStatus: [ORDER_STATUS.SCHEDULED],
  },
  {
    id: 5,
    title: 'Others',
    orderStatus: [
      ORDER_STATUS.DRIVER_ASSIGNED,
      ORDER_STATUS.DRIVER_ARRIVED,
      ORDER_STATUS.PICKEDUP,
    ],
  },
];

export const PAST_ORDER_TABS = [
  {
    id: 1,
    title: 'All',
    orderStatus: [
      ORDER_STATUS.CANCELLED,
      ORDER_STATUS.DELIVERED,
      ORDER_STATUS.VOIDED,
    ],
  },
  {
    id: 2,
    title: 'Complete',
    orderStatus: [ORDER_STATUS.DELIVERED],
  },
  {
    id: 3,
    title: 'Cancelled',
    orderStatus: [ORDER_STATUS.CANCELLED],
  },
  {
    id: 4,
    title: 'Voided',
    orderStatus: [ORDER_STATUS.VOIDED],
  },
];

export const ORDER_SEARCH_PARAMS = [
  {
    key: 'filterByExternalId',
    title: 'External ID',
  },
  {
    key: 'filterByOrderId',
    title: 'Order ID',
  },
];

export const KLIKIT_ORDER_SOURCES = [
  { id: 1, title: 'Add Order', filterByAddOrder: true },
  { id: 2, title: 'Webshop', filterByAddOrder: false },
];

export const PAST_ORDER_DATE_FORMAT = 'yyyy-MM-dd';

export const CANCELLED_ORDER_STATUS_IDS = [
  ORDER_STATUS.CANCELLED,
  ORDER_STATUS.VOIDED,
];

export const CANCELLED_FULFILLMENT_IDS = [
  ORDER_FULFILLMENT_STATUS.CANCELLED.id,
  ORDER_FULFILLMENT_STATUS.FAILED.id,
  ORDER_FULFILLMENT_STATUS.DISPATCH_FAILED.id,
];

export const RIDER_FULFILLMENT_IDS = [
  ORDER_FULFILLMENT_STATUS.PENDING_PICKUP.id,
  ORDER_FULFILLMENT_STATUS.PICKING_UP.id,
  ORDER_FULFILLMENT_STATUS.IN_DELIVERY.id,
  ORDER_FULFILLMENT_STATUS.COMPLETED.id,
  ORDER_FULFILLMENT_STATUS.IN_RETURN.id,
];

export const IN_HOUSE_FULFILLMENT_PROVIDER_ID = 1;

export const TIKTOK_SHOP_STATUSES = {
  ON: {
    id: 1,
    title: 'On',
    iconCls: 'text-green-dark',
  },
  OFF: {
    id: 0,
    title: 'Off',
    iconCls: 'text-grey-dark',
  },
  PENDING: {
    id: 2,
    title: 'Pending',
    iconCls: 'text-yellow-dark',
  },
};
