import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { isEmpty, isFunction } from '../../utils';
import makeRequest from '../../utils/api';
import {
  ACTION_RESOLVED,
  DELETE_ENTITY,
  GET_ENTITY,
  GET_LIST,
  SAVE_ENTITY,
  UPDATE_ENTITY,
} from './constants';

function* getList(req) {
  const {
    reducerKey,
    loaderKey,
    url,
    method,
    params,
    isPaginated = true,
    callbackFn,
    errorCallbackFn,
  } = req.meta || {};

  try {
    const payload = {
      url,
      method,
      params,
    };
    const res = yield call(makeRequest, payload);
    const list = res.data.results || res.data || [];

    yield put({
      type: ACTION_RESOLVED,
      meta: { loaderKey },
    });

    if (!isEmpty(reducerKey)) {
      const successReducerPayload = {
        [reducerKey]: list,
      };

      if (isPaginated) {
        successReducerPayload.total = res.data.total;
        successReducerPayload.page = res.data.page;
        successReducerPayload.size = res.data.size;
      }

      yield put({
        type: UPDATE_ENTITY,
        meta: successReducerPayload,
      });
    }

    if (isFunction(callbackFn)) {
      callbackFn(list, res);
    }
  } catch (err) {
    yield put({
      type: ACTION_RESOLVED,
      meta: { loaderKey },
    });

    if (!isEmpty(reducerKey)) {
      yield put({
        type: UPDATE_ENTITY,
        meta: {
          [reducerKey]: [],
        },
      });
    }

    if (isFunction(errorCallbackFn)) {
      errorCallbackFn(err);
    }

    const { message } = err?.response?.data || {};
    toast.error(message || `Failed to load ${reducerKey}`);
  }
}

function* getEntity(req) {
  const {
    reducerKey,
    loaderKey,
    url,
    method,
    params,
    callbackFn,
    errorCallbackFn,
  } = req.meta || {};

  try {
    const payload = {
      url,
      method,
      params,
    };
    const res = yield call(makeRequest, payload);
    const data = res.data.results || res.data || {};

    yield put({
      type: ACTION_RESOLVED,
      meta: { loaderKey },
    });

    if (!isEmpty(reducerKey)) {
      const successReducerPayload = {
        [reducerKey]: data,
      };

      yield put({
        type: UPDATE_ENTITY,
        meta: successReducerPayload,
      });
    }

    if (isFunction(callbackFn)) {
      callbackFn(data, res);
    }
  } catch (err) {
    yield put({
      type: ACTION_RESOLVED,
      meta: { loaderKey },
    });

    if (!isEmpty(reducerKey)) {
      yield put({
        type: UPDATE_ENTITY,
        meta: {
          [reducerKey]: [],
        },
      });
    }

    if (isFunction(errorCallbackFn)) {
      errorCallbackFn(err);
    }

    const { message } = err?.response?.data || {};
    toast.error(message || `Failed to load ${reducerKey}`);
  }
}

function* saveEntity(req) {
  const {
    reducerKey,
    loaderKey,
    url,
    method,
    data,
    showToast = true,
    callbackFn,
    errorCallbackFn,
  } = req.meta || {};

  try {
    const payload = {
      url,
      method,
      data,
    };
    const res = yield call(makeRequest, payload);

    yield put({
      type: ACTION_RESOLVED,
      meta: { loaderKey },
    });

    if (isFunction(callbackFn)) {
      callbackFn(res);
    }

    if (showToast) {
      const { message } = res.data || {};
      toast.success(message || `${reducerKey} saved successfully`);
    }
  } catch (err) {
    yield put({
      type: ACTION_RESOLVED,
      meta: { loaderKey },
    });

    if (!isEmpty(reducerKey)) {
      yield put({
        type: UPDATE_ENTITY,
        meta: {
          [reducerKey]: [],
        },
      });
    }

    if (isFunction(errorCallbackFn)) {
      errorCallbackFn(err);
    }

    if (showToast) {
      const { message } = err?.response?.data || {};
      toast.error(message || `Failed to save ${reducerKey}`);
    }
  }
}

function* deleteEntity(req) {
  const {
    reducerKey,
    loaderKey,
    url,
    method,
    params,
    showToast = true,
    callbackFn,
    errorCallbackFn,
  } = req.meta || {};

  try {
    const payload = {
      url,
      method,
      params,
    };
    const res = yield call(makeRequest, payload);

    yield put({
      type: ACTION_RESOLVED,
      meta: { loaderKey },
    });

    if (isFunction(callbackFn)) {
      callbackFn(res);
    }

    if (showToast) {
      const { message } = res.data || {};
      toast.success(message || `${reducerKey} deleted successfully`);
    }
  } catch (err) {
    yield put({
      type: ACTION_RESOLVED,
      meta: { loaderKey },
    });

    if (!isEmpty(reducerKey)) {
      yield put({
        type: UPDATE_ENTITY,
        meta: {
          [reducerKey]: [],
        },
      });
    }

    if (isFunction(errorCallbackFn)) {
      errorCallbackFn(err);
    }

    if (showToast) {
      const { message } = err?.response?.data || {};
      toast.error(message || `Failed to delete ${reducerKey}`);
    }
  }
}

export default function* Saga() {
  yield takeEvery(GET_LIST, getList);
  yield takeEvery(GET_ENTITY, getEntity);
  yield takeLatest(SAVE_ENTITY, saveEntity);
  yield takeLatest(DELETE_ENTITY, deleteEntity);
}
