import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCButton, SCLoader } from 'rollup-allspark';
import HelpPopover from '../../../components/HelpPopover';
import KTTooltip from '../../../components/KTTooltip';
import { DISCOUNT_HELP } from '../../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import { isEmpty } from '../../../utils';
import { readablePrice } from '../../../utils/getCurrency';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import {
  calculateDiscountProviderSpecific,
  calculateDiscountTotal,
  generateDiscountCSVData,
  generateXLSXDataFromCSVData,
  IncorrectMerChantDiscountProviders,
} from '../../../utils/nativeAnalyticsUtils';
import {
  downloadCsvFromJSON,
  downloadXLSXFromJSON,
} from '../../../utils/sheetUtils';
import reducer from '../reducer';
import saga from '../saga';
import * as selectors from '../selectors';

const DiscountTable = ({
  analyticsProviders,
  summary,
  summaryLoading,
  comparisonEnabled,
}) => {
  useInjectReducer({ key: 'nativeAnalytics', reducer });
  useInjectSaga({ key: 'nativeAnalytics', saga });

  // CONSTANTS
  const headerKeys = [
    {
      title: 'Aggregators',
      align: 'left',
      slug: 'provider_name',
    },
    {
      title: 'Merchant Shouldered Discount',
      align: 'right',
      slug: 'merchant_discount',
    },
    {
      title: 'Aggregator Shouldered Discount',
      align: 'right',
      slug: 'provider_discount',
    },
    {
      title: 'Total Discount',
      align: 'right',
      slug: 'discount',
    },
    {
      title: 'Currency',
      align: 'left',
      slug: 'currency',
    },
  ];

  // HOOKS
  const [isDiscountPopoverOpen, setIsDiscountPopoverOpen] = useState(false);
  const [tableRowData, setTableRowData] = useState([]);
  const [
    possibleIncorrectMerChantDiscountProviders,
    setPossibleIncorrectMerChantDiscountProviders,
  ] = useState([]);

  useEffect(() => {
    const analyticsSummaryData =
      summary && summary.summary ? summary.summary : [];
    let discountData;
    if (comparisonEnabled) {
      discountData = calculateDiscountProviderSpecific(
        analyticsSummaryData,
        analyticsProviders,
      );
      // Filter out the providers that have incorrect merchant discount
      const filteredProviders = analyticsSummaryData.filter(data =>
        IncorrectMerChantDiscountProviders.includes(data.provider_id),
      );
      setPossibleIncorrectMerChantDiscountProviders(filteredProviders);
    } else {
      discountData = calculateDiscountTotal(analyticsSummaryData);
    }

    setTableRowData([...discountData]);
  }, [summary, comparisonEnabled]);

  // HANDLERS AND GENERATORS
  const handleCSVDownload = () => {
    const CSVData = generateDiscountCSVData(headerKeys, tableRowData);

    downloadCsvFromJSON(
      CSVData,
      `Discount-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  const handleXLSXDownload = () => {
    const CSVData = generateDiscountCSVData(headerKeys, tableRowData);
    const XLSXData = generateXLSXDataFromCSVData(CSVData);

    downloadXLSXFromJSON(
      XLSXData,
      `Discount-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  // RENDERS

  // actionButton
  const renderDownloadCSVButton =
    tableRowData && tableRowData.length ? (
      <div className="flex items-center gap-x-2">
        <SCButton
          variant="secondary"
          size="sm"
          className="rounded-lg bg-grey-light text-black hover:bg-grey gap-x-1 w-auto flex items-center px-4 h-6 text-xs font-medium"
          action={handleCSVDownload}
        >
          <i className="kt-download" />
          <p>CSV</p>
        </SCButton>
        <SCButton
          label="Download"
          variant="secondary"
          size="sm"
          className="rounded-lg bg-grey-light text-black hover:bg-grey gap-x-1 w-auto flex items-center px-4 h-6 text-xs font-medium"
          action={handleXLSXDownload}
        >
          <i className="kt-download" />
          <p>XLSX</p>
        </SCButton>
      </div>
    ) : null;

  // loaders
  const renderLoader = (
    <div className="flex items-center justify-center h-48 w-full">
      <SCLoader lineWidth={5} diameter={50} />
    </div>
  );

  // tableHeader
  const merchantShoulderedDiscountTooltipContent = (() => {
    const matchedProviders = possibleIncorrectMerChantDiscountProviders.map(
      incorrectProvider => {
        const matchedProvider = analyticsProviders.find(
          provider => provider.id === incorrectProvider.provider_id,
        );
        return matchedProvider ? matchedProvider.title : '';
      },
    );

    return (
      <div>
        <p className="mb-2">
          Merchant Shouldered discount might not be correct for{' '}
        </p>
        <div className="flex flex-wrap gap-2">
          {matchedProviders.map(providerTitle => (
            <span
              className="p-2 bg-grey rounded-full capitalize"
              key={providerTitle}
            >
              {providerTitle}
            </span>
          ))}
        </div>
      </div>
    );
  })();

  const baseClass = ['bg-grey-lighter border-b capitalize p-3'];
  const renderHeaderCol = headerKeys
    .filter(cell => !['Currency', 'Total Discount'].includes(cell.title))
    .map((cell, index) => {
      const alignClass = `text-${cell.align}`;
      const showMerchantTooltip =
        cell.slug === 'merchant_discount' &&
        comparisonEnabled &&
        !isEmpty(possibleIncorrectMerChantDiscountProviders);
      return (
        <th
          key={`discount-header-cell-${index + 1}`}
          className={`${baseClass} ${alignClass}`}
        >
          <div className="flex items-center">
            {cell.title}
            {showMerchantTooltip && (
              <KTTooltip
                content={merchantShoulderedDiscountTooltipContent}
                positions={['top']}
              >
                <i className="sc-information cursor-pointer ml-2 mt-1" />
              </KTTooltip>
            )}
          </div>
        </th>
      );
    });
  // tableBody
  const formatPrice = (price, currency, symbol) => {
    if (price !== '-') {
      return readablePrice(price, { code: currency, symbol });
    }
    return '-';
  };
  const renderTableBodyRows =
    tableRowData && tableRowData.length ? (
      tableRowData.map((cell, index) => (
        <tr key={`discount-row-${index + 1}`}>
          <td className="p-4 border-r border-b">{cell.provider_name}</td>
          <td className="p-4 text-right border-b border-r ">
            {formatPrice(
              cell.merchant_discount,
              cell.currency,
              cell.currency_symbol,
            )}
          </td>
          <td className="p-4 text-right border-b border-r ">
            {formatPrice(
              cell.provider_discount,
              cell.currency,
              cell.currency_symbol,
            )}
          </td>
        </tr>
      ))
    ) : (
      <tr className="text-center">
        <td colSpan="11" className="p-5 text-2xl text-grey-darker h-20">
          No data Found
        </td>
      </tr>
    );
  // table
  const renderTable = (
    <table className="table-auto w-full">
      <thead>
        <tr>{renderHeaderCol}</tr>
      </thead>
      <tbody>{renderTableBodyRows}</tbody>
    </table>
  );

  return (
    <div className="w-full bg-white min-h p-4 rounded">
      <div className="w-full flex mb-4 justify-between">
        <div className="flex">
          <h5 className="text-base font-medium capitalize mr-2">Discount</h5>
          <HelpPopover
            isPopoverOpen={isDiscountPopoverOpen}
            position="right"
            isHtmlElement
            content={DISCOUNT_HELP}
            setIsPopoverOpen={setIsDiscountPopoverOpen}
            iconClassNames="sc-information cursor-pointer"
          />
        </div>
        {renderDownloadCSVButton}
      </div>
      {/* <div className="text-sm mb-3">
        The list of discount defined filter type and time period
      </div> */}
      <div className="flex items-center mb-4 mt-4">
        {summaryLoading ? renderLoader : renderTable}
      </div>
    </div>
  );

  // Tabs
};

DiscountTable.propTypes = {
  analyticsProviders: PropTypes.array,
  summary: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  summaryLoading: PropTypes.bool,
  comparisonEnabled: PropTypes.bool,
};
const mapStateToProps = createStructuredSelector({
  analyticsProviders: selectors.makeSelectAnalyticsProviders(),
  summary: selectors.makeSelectSummary(),
  summaryLoading: selectors.makeSelectSummaryLoading(),
  comparisonEnabled: selectors.makeSelectComparisonEnabled(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(DiscountTable);
