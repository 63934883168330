import {
  DELETE_ENTITY,
  GET_ENTITY,
  GET_LIST,
  RESET_DATA,
  SAVE_ENTITY,
  UPDATE_ENTITY,
} from './constants';

export function getList(meta) {
  return {
    type: GET_LIST,
    meta,
  };
}

export function getEntity(meta) {
  return {
    type: GET_ENTITY,
    meta,
  };
}

export function saveEntity(meta) {
  return {
    type: SAVE_ENTITY,
    meta,
  };
}

export function deleteEntity(meta) {
  return {
    type: DELETE_ENTITY,
    meta,
  };
}

export function updateEntity(meta) {
  return {
    type: UPDATE_ENTITY,
    meta,
  };
}

export function resetData() {
  return {
    type: RESET_DATA,
  };
}
