import PropTypes from 'prop-types';
import React from 'react';
import { SCInput } from 'rollup-allspark';
import { isEmpty } from '../utils';

const SCInputWithPrefixSuffix = ({
  handleChange,
  className = 'border-grey-dark rounded-sm bg-white',
  value,
  style = { height: 38 },
  placeholder = '',
  prefix,
  suffix,
  ...props
}) => (
  <div className={`flex items-center border px-2 ${className}`} style={style}>
    {!isEmpty(prefix) ? <p>{prefix}</p> : null}
    <SCInput
      type="number"
      inputClass="border-none flex-1"
      placeholder={placeholder}
      value={value}
      handleChange={handleChange}
      {...props}
    />
    {!isEmpty(suffix) ? <p>{suffix}</p> : null}
  </div>
);

SCInputWithPrefixSuffix.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

export default SCInputWithPrefixSuffix;
