import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCButton, SCLoader, SCPagination } from 'rollup-allspark';
import NotificationImg from '../../../assets/images/Notification.png';
import HelpPopover from '../../../components/HelpPopover';
import {
  EXPORT_TYPE,
  ORDER_BREAKDOWN_TYPE,
} from '../../../config/constants/analytics';
import { DEFAULT_PAGE_SIZES } from '../../../config/constants/pagination';
import { ORDER_BREAKDOWN_HELP } from '../../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import {
  getXLSX,
  loadAnalyticsCsv,
  loadOrdersData,
  updatePagination,
} from '../actions';
import {
  EXPORT_ORDER_BREAKDOWN_ITEM,
  EXPORT_ORDER_BREAKDOWN_ORDER,
} from '../sagaMap';
import * as selectors from '../selectors';
import OrderTableHeader from './OrderTableHeader';
import OrderTableRow from './OrderTableRow';

const OrderTable = ({
  ordersData,
  ordersDataLoading,
  paginationObj,
  handleUpdatePagination,
  filterData,
  getOrdersData,
  handleAnalyticsCsvDownload,
  isMoreThan30Days,
  downloadXLSX,
}) => {
  const { orders, pagination } = ordersData || {};
  let renderOrdersDataRow = null;
  const { pageNo, pageSize } = paginationObj || {};
  const [isOrderBreakdownPopoverOpen, setIsOrderBreakdownPopoverOpen] =
    useState(false);
  const [showMoreThan30DaysPlaceholder, setShowMoreThan30DaysPlaceholder] =
    useState(false);

  const getFilteredOrderData = () => {
    const params = { ...filterData };
    params.page_no = pageNo;
    params.page_size = pageSize;
    if (params.brand_id || params.branch_id) {
      getOrdersData(params);
    }
  };

  useEffect(getFilteredOrderData, [paginationObj]);

  useEffect(() => {
    setShowMoreThan30DaysPlaceholder(isMoreThan30Days);
  }, [isMoreThan30Days]);

  const onAnalyticsDownloadButtonClick = (
    breakdownType = ORDER_BREAKDOWN_TYPE.ORDER,
  ) => {
    const params = {
      ...filterData,
      export_option: EXPORT_TYPE.CSV,
      breakdownType,
    };
    handleAnalyticsCsvDownload(params);
  };

  const onXlsxDownloadButtonClick = (
    breakdownType = ORDER_BREAKDOWN_TYPE.ORDER,
  ) => {
    const params = {
      ...filterData,
      export_option: EXPORT_TYPE.XLSX,
      breakdownType,
    };

    const map =
      breakdownType === ORDER_BREAKDOWN_TYPE.ORDER
        ? EXPORT_ORDER_BREAKDOWN_ORDER
        : EXPORT_ORDER_BREAKDOWN_ITEM;

    downloadXLSX({
      ...map,
      params,
      fileName: `Analytics-${breakdownType}`,
    });
  };

  if (ordersDataLoading) {
    renderOrdersDataRow = (
      <tr>
        <td
          rowSpan="10"
          colSpan="12"
          className="p-5"
          style={{ height: '380px' }}
        >
          <SCLoader lineWidth={5} diameter={50} />
        </td>
      </tr>
    );
  } else {
    renderOrdersDataRow =
      orders && orders.length ? (
        orders.map(order => <OrderTableRow key={order.id} orderData={order} />)
      ) : (
        <tr className="text-center">
          <td colSpan="11" className="p-5 text-2xl text-grey-darker h-20">
            No data Found
          </td>
        </tr>
      );
  }

  const getCsvInEmailEl = (
    <tr className="text-center">
      <td colSpan="14" className="p-5 text-2xl text-grey-darker h-20">
        <div className="flex justify-center items-center flex-col w-full gap-y-1">
          <img
            src={NotificationImg}
            alt="Notification"
            width={150}
            height={150}
          />
          <h3 className="text-xl text-black">Date range Exceeds 30 Days</h3>
          <p className="text-grey-darker text-sm">
            Let us send the data in your mail.
          </p>
          <div className="flex gap-x-4 divide-x divide-grey mt-8">
            <div className="flex flex-col gap-y-4">
              <div className="text-sm text-grey-darker">Order Breakdown</div>
              <SCButton
                variant="secondary"
                className="rounded-lg bg-grey-light text-black hover:bg-grey gap-x-1 w-auto flex items-center px-4 text-sm"
                action={() =>
                  onAnalyticsDownloadButtonClick(ORDER_BREAKDOWN_TYPE.ORDER)
                }
              >
                <i className="flex items-center sc-mail" />
                Get the CSV in Email
              </SCButton>
              <SCButton
                variant="secondary"
                className="rounded-lg bg-grey-light text-black hover:bg-grey gap-x-1 w-auto flex items-center px-4 text-sm"
                action={() =>
                  onXlsxDownloadButtonClick(ORDER_BREAKDOWN_TYPE.ORDER)
                }
              >
                <i className="flex items-center sc-mail" />
                Get the XLSX in Email
              </SCButton>
            </div>
            <div className="flex flex-col gap-y-4 pl-4">
              <div className="text-sm text-grey-darker">Item Breakdown</div>
              <SCButton
                variant="secondary"
                className="rounded-lg bg-grey-light text-black hover:bg-grey gap-x-1 w-auto flex items-center px-4 text-sm"
                action={() =>
                  onAnalyticsDownloadButtonClick(ORDER_BREAKDOWN_TYPE.ITEM)
                }
              >
                <i className="flex items-center sc-mail" />
                Get the CSV in Email
              </SCButton>
              <SCButton
                variant="secondary"
                className="rounded-lg bg-grey-light text-black hover:bg-grey gap-x-1 w-auto flex items-center px-4 text-sm"
                action={() =>
                  onXlsxDownloadButtonClick(ORDER_BREAKDOWN_TYPE.ITEM)
                }
              >
                <i className="flex items-center sc-mail" />
                Get the XLSX in Email
              </SCButton>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );

  const renderPagination =
    !ordersDataLoading && orders && orders.length && !isMoreThan30Days ? (
      <SCPagination
        total={pagination && pagination.total ? pagination.total : 0}
        page={pageNo}
        size={pageSize}
        sizes={DEFAULT_PAGE_SIZES}
        onPageChange={handleUpdatePagination}
      />
    ) : null;

  const renderDownloadButton =
    orders && orders.length ? (
      <div className="flex gap-x-4 divide-x divide-grey">
        <div className="flex flex-col gap-y-1">
          <div className="text-xs">Order Breakdown</div>
          <div className="flex gap-x-2">
            <SCButton
              variant="secondary"
              size="sm"
              className="h-6 w-auto px-4 text-xs bg-grey-light hover:bg-grey text-black rounded-lg"
              action={() => onAnalyticsDownloadButtonClick()}
            >
              <div className="flex items-center gap-x-1 font-medium">
                <i className="kt-download" />
                <p>CSV</p>
              </div>
            </SCButton>
            <SCButton
              label="Download"
              variant="secondary"
              size="sm"
              className="h-6 w-auto px-4 text-xs bg-grey-light hover:bg-grey text-black rounded-lg"
              action={() => onXlsxDownloadButtonClick()}
            >
              <div className="flex items-center gap-x-1 font-medium">
                <i className="kt-download" />
                <p>XLSX</p>
              </div>
            </SCButton>
          </div>
        </div>
        <div className="flex flex-col gap-y-1 pl-4">
          <div className="text-xs">Item Breakdown</div>
          <div className="flex gap-x-2">
            <SCButton
              variant="secondary"
              size="sm"
              className="h-6 w-auto px-4 text-xs bg-grey-light hover:bg-grey text-black rounded-lg"
              action={() =>
                onAnalyticsDownloadButtonClick(ORDER_BREAKDOWN_TYPE.ITEM)
              }
            >
              <div className="flex items-center gap-x-1 font-medium">
                <i className="kt-download" />
                <p>CSV</p>
              </div>
            </SCButton>
            <SCButton
              label="Download"
              variant="secondary"
              size="sm"
              className="h-6 w-auto px-4 text-xs bg-grey-light hover:bg-grey text-black rounded-lg"
              action={() =>
                onXlsxDownloadButtonClick(ORDER_BREAKDOWN_TYPE.ITEM)
              }
            >
              <div className="flex items-center gap-x-1 font-medium">
                <i className="kt-download" />
                <p>XLSX</p>
              </div>
            </SCButton>
          </div>
        </div>
      </div>
    ) : null;

  return (
    <div className="bg-white w-full p-4 rounded">
      <div className="w-full flex justify-between items-center mb-6">
        <div className="text-base font-medium capitalize flex items-center">
          Order Breakdown
          <HelpPopover
            isPopoverOpen={isOrderBreakdownPopoverOpen}
            position="right"
            isHtmlElement
            content={ORDER_BREAKDOWN_HELP}
            setIsPopoverOpen={setIsOrderBreakdownPopoverOpen}
            iconClassNames="sc-information cursor-pointer ml-2"
          />
        </div>
        <div className="flex items-end gap-x-6">
          <small className="text-center capitalize mr-2 border rounded-full  border-grey-dark py-1 px-2">
            Download to see more data
          </small>
          {renderDownloadButton}
        </div>
      </div>
      <div className="overflow-x-auto mb-8">
        <table className="table-auto w-full">
          <thead>
            <OrderTableHeader />
          </thead>
          <tbody>
            {showMoreThan30DaysPlaceholder
              ? getCsvInEmailEl
              : renderOrdersDataRow}
          </tbody>
        </table>
      </div>
      <div className="w-full flex justify-center">{renderPagination}</div>
    </div>
  );
};

OrderTable.propTypes = {
  ordersData: PropTypes.object,
  ordersDataLoading: PropTypes.bool,
  paginationObj: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  filterData: PropTypes.object,
  getOrdersData: PropTypes.func,
  handleAnalyticsCsvDownload: PropTypes.func,
  isMoreThan30Days: PropTypes.bool,
  downloadXLSX: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  ordersData: selectors.makeSelectOrdersData(),
  ordersDataLoading: selectors.makeSelectOrdersDataLoading(),
  paginationObj: selectors.makeSelectPaginationObj(),
  analyticsStartDate: selectors.makeSelectAnalyticsStartDate(),
  analyticsEndDate: selectors.makeSelectAnalyticsEndDate(),
  isMoreThan30Days: selectors.makeSelectIsMoreThan30Days(),
});

const mapDispatchToProps = dispatch => ({
  handleUpdatePagination: (page, size) =>
    dispatch(updatePagination(page, size)),
  getOrdersData: params => dispatch(loadOrdersData(params)),
  handleAnalyticsCsvDownload: params => dispatch(loadAnalyticsCsv(params)),
  downloadXLSX: params => dispatch(getXLSX(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrderTable);
